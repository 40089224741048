export default {
  update: function(el) {
    const upper = el.value.toLowerCase();
    if (upper !== el.value) {
      var event = new Event('input')
      el.value = upper
      el.dispatchEvent(event)
    }
  }
}
