import encuesta from '@/modules/encuesta/Encuesta.store'
import geo from '@/modules/encuesta/Geolocalization.store'
import escuela from '@/modules/escuelas/Escuela.store'
import tickets from '@/modules/tickets/Ticket.store'
import tablero from '@/modules/tablero/Tablero.store'
import pendiente from '@/modules/pendientes/Pendiente.store'
import axios from 'axios'

const client_id     = process.env.VUE_APP_CLIENT_ID;
const client_secret = process.env.VUE_APP_CLIENT_SECRET;
const create_code_verifier = () => {
  const array = new Uint32Array(28)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('')
}
// Returns the 'code_challenge' given a 'code_verifier'
const create_code_challenge = async v => {
  const encoder = new TextEncoder()
  const hashed  = await window.crypto.subtle.digest('SHA-256', encoder.encode(v))
  // Convert the ArrayBuffer to string using Uint8 array
  // and convert the array to a string of chars ascii 0-255
  const binaryStr = String.fromCharCode.apply(null, new Uint8Array(hashed))
  // Create the base64 and encodes as a base64url encoded
  return btoa(binaryStr)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
}

export const getFormUrlEncoded =
  obj => Object.entries(obj).map( ([k, v]) => `${k}=${v}`).join('&')

export const urlEncodedconfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
}

const store = {
  modules: { encuesta, geo, escuela, tickets, tablero,pendiente },
  state: {
    username: undefined,
    access_token: undefined,
    refresh_token: null,
    constants: undefined,
    info: null,
    // expires_in: 36000,
    // token_type: "Bearer",
    // scope: "read write groups introspection",
  },
  mutations: {
    SET_LOGIN: (s, data) => {
      window.localStorage.setItem('access_token', data.access_token)
      window.localStorage.setItem('refresh_token', data.refresh_token)
      s.access_token  = data.access_token
      s.refresh_token = data.refresh_token
    },
    SET_CONSTANTS: (s, v) => s.constants = v,
    SET_USER_INFO: (s, v) => s.info = v
  },
  actions: {
    resetAuthorize() {
      window.localStorage.removeItem('code_verifier')
    },
    async goToAuthorize() {
      const code_verifier  = create_code_verifier()
      const code_challenge = await create_code_challenge(code_verifier)
      const code_challenge_method = 'S256'
      const url = process.env.VUE_APP_BACK_END + 'o/authorize/?' + getFormUrlEncoded({
        response_type: 'code',
        client_id, code_challenge, code_challenge_method,
        redirect_uri: process.env.VUE_APP_FRONT_END,
      })
      window.localStorage.setItem('code_verifier', code_verifier)
      window.location.href = url
    },
    login({ commit, dispatch }, code) {
      const code_verifier = window.localStorage.getItem('code_verifier')
      dispatch('resetAuthorize')

      const data = getFormUrlEncoded({
        client_id, client_secret, code, code_verifier,
        redirect_uri: process.env.VUE_APP_FRONT_END,
        grant_type: 'authorization_code',
      })
      return axios.post('/o/token/', data, { config: urlEncodedconfig }).then(
        resp => commit('SET_LOGIN', resp.data)
      )
    },
    refresh({ commit, state }) {
      if (!state.refresh_token) { return new Promise() }
      const data = getFormUrlEncoded({
        client_id, client_secret,
        refresh_token: state.refresh_token,
        grant_type: 'refresh_token',
      })

      return axios.post('/o/token/', data, { config: urlEncodedconfig }).then( r => {
        commit('SET_LOGIN', r.data)
        return r.data
      })
    },
    logout({ commit }) {
      // TODO Revoke token
      commit('SET_LOGIN', {})
      commit('SET_USER_INFO', null)
      return Promise.resolve()
    },
    loadConstants({ commit }) {
      return axios.get('/midecc/constants/').then( resp => {
        commit('SET_CONSTANTS', resp.data)
        return resp.data
      })
    },
    fetchUserInfo({ commit }) {
      return axios.get('/midecc/user-info/').then( resp => {
        commit('SET_USER_INFO', resp.data)
        return resp.data
      })
    },
    createUserInfo(_, userInfo) {
      return axios.post('/midecc/user-info/', userInfo).then( resp => resp.data )
    },
  },
  getters: {
    directorOf: state => clave =>
      state.info &&
      state.info.perfil === 'director' &&
      state.info.escuela === clave,
    isStaff: state => state.info && state.info.staff,
  },
}

export default store

export const AuthGuard = (to, from, next) => {
  if (store.state.access_token) {
    return next()
  } else {
    return next({ path: '/' })
  }
}

export const StaffGuard = (to, from, next) => {
  if (store.state.info && store.state.info.staff) {
    return next()
  } else {
    return next({ path: '/' })
  }
}

