import axios from 'axios';

export default store => ({
  install() {
    axios.defaults.baseURL = process.env.VUE_APP_BACK_END;

    let requests = 0
    const increment = () => {
      requests++
      document.body.classList.add('loading')
    }
    const decrement = () => {
      requests--
      if (requests <= 0) {
          requests = 0
          document.body.classList.remove('loading')
      }
    }

    axios.interceptors.request.use( config => {
      const { access_token } = store.state
      if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`
      }

      increment()
      return config
    })

    axios.interceptors.response.use(
      // Caso correcto
      resp => {
        decrement()
        return resp
      },
      // Caso incorrecto
      async err => {
        if (err.response && err.response.status === 401) {
          const data = await store.dispatch('refresh')
          if (!data || !data.access_token) {
            store.dispatch('logout')
            return Promise.reject(err)
          }
          return axios(err.config)
        }
        decrement()
        return Promise.reject(err)
      }
    )
  }
})
