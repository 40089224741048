<template>
  <ValidationObserver ref="vObserver" v-slot="{ valid, invalid }" :disabled="true">
  <form class="flex-row wrap" @submit.prevent="valid && next()">
    <h2 class="full-width">Domicilio {{ append }}</h2>
    <div class="full-width"><hr class="gob" /></div>

    <ValidationProvider class="field calle" rules="required" v-slot="v" name="calle">
      <b-form-group label="Calle*">
        <b-input v-model="calle" :state="$state(v)"
          maxLength="100" v-uppercase></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <div class="flex-row numeros">
      <ValidationProvider class="field ext" v-slot="v" name="numExt">
      <b-form-group label="No. Ext.">
        <b-input v-model="numExt" :state="$state(v)"
          maxLength="10" v-uppercase></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field int" v-slot="v" name="numInt">
      <b-form-group label="No. Int.">
        <b-input v-model="numInt" :state="$state(v)"
          maxLength="10" v-uppercase>
        </b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
      </ValidationProvider>
    </div>

    <div class="grid">
    <span class="field estado">
      <b-form-group label="Estado*">
        <b-input :value="estado ? estado.nombre : ''" disabled></b-input>
      </b-form-group>
    </span>

    <ValidationProvider class="field municipio" rules="required" v-slot="v" name="municipio">
      <b-form-group label="Municipio*">
        <b-select v-model="municipio" @change="updateMunicipio" plain :state="$state(v)">
          <b-option :value="undefined">-- --</b-option>
          <b-option v-for="m of municipios" :key="m.id" :value="m">
            {{ m.nombre }}
          </b-option>
        </b-select>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" v-slot="v" name="asentamiento">
      <b-form-group label="Asentamiento*">
        <b-select v-model="asentamiento" :state="$state(v)" plain
          :disabled="!municipio" @change="updateAsentamiento">
          <b-option :value="undefined">-- No encontrado --</b-option>
          <b-option v-for="a of asentamientos" :key="a.id" :value="a">
            {{ a.nombre }}
          </b-option>
        </b-select>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" v-slot="v" name="cp">
      <b-form-group label="Código postal">
        <b-input v-model="codPost" :state="$state(v)" disabled></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>
    </div>

    <ValidationProvider class="field referencia" v-slot="v" name="asentamiento_alt"
      :rules="asentamiento ? null : 'required'"
    >
      <b-form-group>
        <template #label>
          Asentamiento<span v-if="!asentamiento">*</span> (solo si es -- No encontrado --)
        </template>
        <b-input v-model="asentamiento_alt" :state="$state(v)"
          :disabled="!!asentamiento"
          maxLength="200"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field referencia" v-slot="v" name="referencia">
      <b-form-group label="Referencia">
        <b-input v-model="referencia" :state="$state(v)"
          maxLength="200"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <b-alert :show="!!asentamiento" class="full-width text-center">
      <small>
        Da clic en el mapa para indicar la
        ubicacion de tu Unidad Educativa
      </small>
    </b-alert>

    <div v-show="asentamiento" class="grid">
      <ValidationProvider class="field" rules="required" v-slot="v" name="lat">
        <b-form-group label="Latitud*">
          <b-input v-model="latitud" :state="$state(v)" disabled></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" rules="required" v-slot="v" name="lng">
        <b-form-group label="Longitud*">
          <b-input v-model="longitud" :state="$state(v)" disabled></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>
    </div>
    <div id="map" v-show="!!municipio"></div>


    <div v-if="!hideButtons"
      class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid"
        @click.prevent="next()">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>

  </ValidationObserver>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    value: Object,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
    hideButtons: { default: false },
    perimeterValidation: { default: true },
    valid: { default: null }
  },
  data: () => ({
    // Value attributes
    calle: undefined,
    numExt: undefined,
    numInt: undefined,
    municipio: undefined,
    asentamiento: undefined,
    asentamiento_alt: undefined,
    codPost: undefined,
    referencia: undefined,
    latitud: undefined,
    longitud: undefined,
    // Aux attributes
    google: null,
    maps: null,
    multipolygon: null,
    marker: null,
  }),
  created() {
    this.initMaps().then( () => {
      if (!this.escuela) return

      this.calle = this.domicilio.calle
      this.numExt = this.domicilio.numExt
      this.numInt = this.domicilio.numInt
      this.referencia = this.domicilio.referencia
      this.asentamiento_alt = this.domicilio.asentamiento ?
        undefined :
        this.domicilio.asentamiento_alt

      this.setEstado(this.domicilio.estado)
      this.fetchMunicipios().then( municipios => {
        this.municipio = municipios.find( m => m.id === this.domicilio.municipio.id )
        this.setMunicipio(this.municipio)
      })
        .then( () => this.fetchAsentamientos() )
        .then( asentamientos => {
          if (this.perimeterValidation) {
            this.drawPerimeter()
          }
          if (this.domicilio.asentamiento) {
            this.asentamiento = asentamientos.find( a => a.id === this.domicilio.asentamiento.id )
            this.codPost = this.asentamiento.codigo_postal
          } else {
            this.asentamiento_alt = this.domicilio.asentamiento_alt
          }

          const latLng = new this.google.maps.LatLng(
            this.domicilio.latitud,
            this.domicilio.longitud)
          this.drawMarker({ latLng })
          this.map.setCenter(latLng)
        })
    })
  },
  computed: {
    ...mapState('geo', ['estado', 'municipios', 'asentamientos']),
    ...mapState('escuela', ['escuela', 'domicilio']),
  },
  methods: {
    ...mapActions('geo', [
      'setEstado', 'setMunicipio', 'fetchMunicipios', 'fetchAsentamientos',
      'centerMunicipio', 'validateCoords', 'getPerimeter'
    ]),
    async initMaps() {
      try {
        const loader = new Loader({
          apiKey: process.env.VUE_APP_API_KEY,
          version: process.env.VUE_APP_API_VERSION,
          libraries: ['places']
        })

        this.google = await loader.load()
        this.map    = new this.google.maps.Map(
          document.getElementById("map"),
          { zoom: 12 } // center: { lat: 19.5, lng: -99.1 },
        )
        this.map.addListener('click', this.validaClick)
      } catch(e) {
        console.log(e)
      }
    },
    updateMunicipio() {
      this.setMunicipio(this.municipio)
      if(this.municipio) {
        this.fetchAsentamientos()
        this.findLocation()
        if (this.perimeterValidation) {
          this.drawPerimeter()
        }
      }

      this.asentamiento = undefined
      this.asentamiento_alt = undefined
      this.updateAsentamiento()
    },
    updateAsentamiento() {
      this.latitud  = undefined
      this.longitud = undefined
      if (this.marker)  { this.marker.setMap(null) }
      if (!this.asentamiento) {
        this.asentamiento = undefined
        this.codPost = undefined
        return
      }
      this.codPost = this.asentamiento.codigo_postal
    },
    findLocation() {
      this.centerMunicipio(this.municipio.codigo).then( coords => {
        this.map.setCenter(coords)
        this.map.setZoom(12)
      })
    },
    toastError(err, position) {
      let infowindow = new this.google.maps.InfoWindow({
        content: err,
        position,
      });
      infowindow.open({ map: this.map })
      setTimeout( () => {
        infowindow.close()
        infowindow = undefined
      }, 1000 )
    },
    drawPerimeter() {
      this.getPerimeter().then( geojson => {
        if(this.multipolygon) {
          this.multipolygon.setMap(null)
        }

        this.multipolygon = new this.google.maps.Data({ map: this.map })
        this.multipolygon.setStyle({
          strokeColor: "#00AAFF",
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: "#00AAFF",
          fillOpacity: 0.1,
        })

        const geometryData = {
          type: 'FeatureCollection', features: [{
            type: 'Feature',
            geometry: geojson
          }]
        }
        this.multipolygon.addGeoJson(geometryData)
        this.multipolygon.addListener('click', this.validaClick)
      })
    },
    validaClick({ latLng }) {
      const coords = { lat: latLng.lat(), lng: latLng.lng() }
      if (this.perimeterValidation) {
        this.validateCoords({ coords }).then( valid => {
          if (valid) {
            this.drawMarker({ latLng })
          } else {
            this.toastError('Ubicación fuera de rango', latLng)
          }
        })
      } else {
        this.drawMarker({ latLng })
      }
    },
    drawMarker({ latLng }) {
      if (this.marker) {
        this.marker.setMap(null)
      }
      this.marker = new this.google.maps.Marker({
        position: latLng,
        map: this.map,
      })
      this.latitud  = latLng.lat()
      this.longitud = latLng.lng()
    },
    next() {
      this.$emit('next')
    },
    valueChanges() {
      this.$emit('input', {
        calle: this.calle || null,
        numExt: this.numExt || null,
        numInt: this.numInt || null,
        municipio: this.municipio || null,
        asentamiento: this.asentamiento || null,
        asentamiento_alt: this.asentamiento_alt || null,
        codPost: this.codPost || null,
        latitud: this.latitud || null,
        longitud: this.longitud || null,
      })
      this.$emit('update:valid',
        !!this.calle &&
        !!this.estado &&
        !!this.municipio &&
        !!(this.asentamiento || this.asentamiento_alt) &&
        !!this.latitud &&
        !!this.longitud
      )
    }
  },
  watch: {
    calle() { this.valueChanges() },
    numExt() { this.valueChanges() },
    numInt() { this.valueChanges() },
    municipio() { this.valueChanges() },
    asentamiento() { this.valueChanges() },
    asentamiento_alt() { this.valueChanges() },
    codPost() { this.valueChanges() },
    latitud() { this.valueChanges() },
    longitud() { this.valueChanges() },
  }
}
</script>

<style scoped>
  .calle { width: 100%; flex-grow: 1; }
  .numeros { width: 100%; }
  .referencia { width: 100%; }
  .numeros > *:first-of-type { margin-right: 20px; }
  .int, .ext { flex-grow: 1; }

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 20px;
  }
  #map { width: 100%; aspect-ratio: 1.8; margin-bottom: 30px; }
  @media screen and (min-width: 760px) {
    .calle   { width: auto; margin-right: 20px; }
    .numeros { width: auto; }
    .ext, .int { max-width: 120px; }
    .grid { grid-template-columns: 1fr 1fr; }
  }
  @media screen and (min-width: 992px) {
    .grid { grid-template-columns: 1fr 1fr 1fr; }
  }
</style>
