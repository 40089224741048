<template>
    <div class="proxy-input" :class="{ small }">
        <template v-if="type === 'string'">
            {{ label }}
            <b-input type="text"
                     :value="value"
                     :state="$state(v)"
                     @input="emit($event)">
            </b-input>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </template>
        <template v-else-if="type === 'boolean'">
            <b-checkbox
                    :checked="value"
                    :state="$state(v)"
                    @change="emit($event)">{{ label }}
            </b-checkbox>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </template>
        <template v-else-if="['date','text','number','email','color','tel','time'].includes(type)">
            {{ label }}
            <b-input :type="type"
                     :value="value"
                     :state="$state(v)"
                     @input="emit($event)">
            </b-input>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </template>
        <template v-else-if="type ==='select'">
            {{ label }}
            <b-form-select
                    v-model="value"
                    :state="$state(v)"
                    @input="emit($event)"
                    value-field="id"
                    text-field="label"
                    :options="values"
            >
            </b-form-select>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </template>
        <div v-else>
            <mark>No supported type: [{{ type }}]</mark>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: null,
        type: {default: 'string'},
        label: null,
        rules: null,
        small: {default: false},
        values: {
            type: Array, default() {
                return [{'value': null, "label": "Selecciona un valor"}]
            }
        },
        v: null,
    },
    methods: {
        emit(v) {
            this.$emit('input', v)
        }
    }
}
</script>

<style scoped>
.small {
    font-size: small;
}

.small input {
    font-size: small;
    padding: 2px 1px;
}
</style>
