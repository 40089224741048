<template>
  <div id="mapContainer">
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl'

export default {
  props: {
    center: { default: () => [-99.1091102, 19.4447791] },
    styleUrl: { default: 'mapbox://styles/jadrians/cjqpu4d9g32dq2so9s7kvq9s7' },
    zoom: { default: 4 },
  },
  data: () => ({
    accesstoken: 'pk.eyJ1IjoiamFkcmlhbnMiLCJhIjoiY2pueHJhajV6MjQyYTNybW82bjVpeWd4dyJ9.CJFib6GB2VmIwf7iJfP-7w',
    map: null,
  }),
  methods: {
    loadMap(loadData) {
      Mapbox.accessToken = this.accesstoken
      this.map = new Mapbox.Map({
        container: 'mapContainer',
        style: this.styleUrl,
        center: this.center,
        zoom: this.zoom,
      })
      this.map.on('load', () => loadData())
    },
    /**
     * @param {string} sourceId
     * @param {Source} spec
     * Ref: https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
     */
    addSource(sourceId, spec) {
      this.map.addSource(sourceId, spec)
    },
    /**
     * @param {string} sourceId
     * @param {string} layerId
     * @param {Layer} spec - Spec without 'source' or 'id' attributes,
     * Ref: https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layer-properties
     */
    addLayer(sourceId, layerId, spec = {}, listeners = {}) {
      this.map.addLayer({
        id: layerId,
        source: sourceId,
        ...spec,
      })

      Object.entries(listeners).forEach(
        ([eventName, callback]) => {

          if (eventName === 'click') {
            this.map.on(eventName, layerId, e => {
              const feature = this.map.queryRenderedFeatures(e.point, { layers: [layerId] })[0]
              const source = this.map.getSource(sourceId)
              callback({ ...e, Mapbox, map: this.map, source, layerId, feature })
            })
            return 
          }
          this.map.on(eventName, layerId, e => callback({ ...e, map: this.map }))
        }
      )
    },
    /**
     *
     */
    getSource(sourceId) {
      return this.map.getSource(sourceId)
    },
    /**
     *
     */
    on(eventName, callback) {
      this.map.on(eventName, callback)
    },
    /**
     *
     */
    setLayerVisibility(layer, visibility) {
      this.map.setLayoutProperty(layer, 'visibility', visibility)
    },
    /**
     *
     */
    getLayervisibility(layer) {
      return this.map.getLayoutProperty(layer, 'visibility')
    },
    /**
     * Receives an 7-length hex string CSS color (e.g. #5F1D0A)
     */
    changeColor(color, action) {
      // TODO Add support to 4-length hex string (#CCC)
      let value = parseInt(color.substring(1), 16)
      const lighten = action === 'lighten'
      const darken  = !lighten
      if ( (darken && value > 0x151515) || (lighten && value + 0x151515 < 0xFFFFFF) ) {
        value += lighten ? 0x151515 : -(0x151515)

      } else if ( (darken && value > 0x001515) || (lighten && value + 0x001515 < 0xFFFFFF) ) {
        value += lighten ? 0x001515 : -(0x001515)

      } else if ( (darken && value > 0x000015) || (lighten && value + 0x000015 < 0XFFFFFF)) {
        value += lighten ? 0x000015 : -(0x000015)
      }
      return '#' + value.toString(16).toUpperCase().padStart(6, '0')
    },
  }
}
</script>

<style>
</style>
