import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

export default {
  install(Vue) {
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);
    extend('required', { ...rules.required, message: 'Campo requerido' })
    extend('regex', { ...rules.regex, message: 'Formato incorrecto' })
    extend('email', { ...rules.email, message: 'Formato incorrecto' })
    extend('truthy', { validate: v => !!v, message: 'Valor incorrecto' })
    extend('is', {
        ...rules.is,
      message: 'Valor incorrecto'
    })
    extend('min', {
      ...rules.min,
      message: (v, { length }) => `Longitud minima de ${length}`,
    })
    extend('max_value', {
      ...rules.max_value,
      message: (v, { max }) => `El valor máximo es ${max}`,
    })
    extend('min_value', {
      ...rules.min_value,
      message: (v, { min }) => `El valor mínimo es ${min}`,
    })
    extend('verificador_clave', {
      validate(v) {
        if (v.length < 10) { return true }

        const EQ = {
          A: '01', B: '02', C: '03', D: '04', E: '05', F: '06', G: '07', H: '08',
          I: '09', J: '10', K: '11', L: '12', M: '13', N: '14', O: '15', P: '16',
          Q: '17', R: '18', S: '19', T: '20', U: '21', V: '22', W: '22', X: '24',
          Y: '25', Z: '26',
        }
        const EQ2 = {
          0:  'A',  1: 'B',  2: 'C',  3: 'D',  4: 'E',  5: 'F',  6: 'G',  7: 'H',
          8:  'I',  9: 'J', 10: 'K', 11: 'L', 12: 'M', 13: 'N', 14: 'O', 15: 'P',
          16: 'Q', 17: 'R', 18: 'S', 19: 'T', 20: 'U', 21: 'V', 22: 'W', 23: 'X',
          24: 'Y', 25: 'Z', 26: 'Z',
        }
        const verifier = v.charAt(9)
        const calc = Array.from(
            // Se remplazan las letras por su equivalencia
            v.substring(0, 9).replace(/[A-Z]/g, c => EQ[c])
          )
          .reduce(
            // Se suman nones y pares por su multiplicador
            (acc, v, index) => acc + (index % 2 == 0 ? 7*Number(v) : 26*Number(v)), 0
          ) % 27

        return verifier === EQ2[calc]
      },
      message: 'Formato incorrecto'
    })
  }
}
