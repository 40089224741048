<template>
  <div class="autocomplete">
    <input type=text class="form-control" :class="classes" v-uppercase
      v-model="internal" @input="onChange()" 
      @focus="open = true" @focusout="hide()"
      @keyup.enter.prevent="setResultEnter()"
      @keydown.down.prevent="select(index + 1)"
      @keydown.up.prevent="select(index - 1)"/>
    <ul v-show="open && results.length > 0">
      <li v-for="(r, i) of results" :key="r"
        :class="{ selected: i === index }"
        @click="setResult(r)">{{ r }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    classes: { default: null },
    value: { required: true },
    items: { default: () => [] },
  },
  data: () => ({
    internal: '',
    internalItems: [],
    results: [],
    open: false,
    index: 0,
  }),
  created() {
    this.internalItems = this.items || []
  },
  methods: {
    unaccent: (v) => v.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
    setResultEnter() {
      if (this.results.length <= 0) { return }
      this.setResult(this.results[this.index])
    },
    setResult(result) {
      this.internal = result
      this.$emit('input', this.internal)
      this.open = false
    },
    showResults() {
      if (this.internal === '') {
        this.results = []
        return
      }

      const search = this.unaccent(this.internal.trim()).toLowerCase()
      this.index = 0
      this.open = true
      this.results = this.internalItems.filter(
        item => this.unaccent(item.trim()).toLowerCase().includes(search)
      )
    },
    onChange() {
      this.$emit('input', this.internal)
      this.showResults()
    },
    hide() {
      setTimeout( () => this.open = false , 300)
    },
    select(index) {
      const length = this.results.length
      if (length <= 0) {
        return
      }
      this.index = index >= 0 ?
        index % length :
        (length + index) % length
    },
  },
  watch: {
    value(newValue) {
      this.internal = newValue
    },
    items(newValue) {
      if (newValue instanceof Promise) {
        newValue.then( items => {
          this.internalItems = items
          this.showResults()
        })
      } else if (newValue instanceof Array) {
        this.internalItems = newValue
      }
    }
  }
}
</script>

<style scoped>
.autocomplete { position: relative; }
.autocomplete ul {
  --border-color: #CCC;
  padding: 0;
  margin: 0;
  border: 1px solid var(--border-color);
  border-top: none;
  min-height: 1em;
  max-height: 600em;
  overflow: auto;
  background-color: white;
  box-shadow: 5px 5px 5px -6px #000;
}
.autocomplete li {
  list-style: none;
  text-align: left;
  padding: 4px 12px;
  cursor: pointer;
  border-top: 1px solid var(--border-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.autocomplete li.selected, .autocomplete li:hover { background: #DDD; }
.autocomplete li.selected:hover { background: #CCC; }
</style>
