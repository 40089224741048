<template>
  <div id="app" class="flex-column">
    <app-header v-if="!$route.meta.hideHeader"></app-header>
    <div class="grow">
      <router-view></router-view>
    </div>
    <app-footer class="no-print"></app-footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppHeader from './components/Header'
import AppFooter from './components/Footer'
export default {
  name: 'App',
  components: { AppHeader, AppFooter },
  created() {
    const { code } = this.$route.query
    if (!code) {
      this.resetAuthorize()
      if (this.$store.state.access_token) {
        this.loadConstants()
      }
      return
    }
    this.login(code)
      .then( () => Promise.all([this.loadConstants(), this.fetchUserInfo()]))
      .then( ([C, info]) => {
        if (info.perfil !== null) {
          this.$router.push({ path: `/escuelas/${info.escuela}` })

        } else if (C.SELF_REGISTRY_DIRECTORS) {
          this.$router.push({ path: `/info?create=true` })

        } else {
          this.$router.push({ path: '/' })
        }
      })
  },
  methods: {
    ...mapActions(['login', 'loadConstants', 'fetchUserInfo', 'logout', 'resetAuthorize']),
  }
}
</script>

<style>
/**
 * Theme / Global Configuration
 */
:root {
  --gob-primary: #0C231E;
  --gob-primary-light: #13322B;
  --gob-contrast: #D4C19C;
  --gob-accent: #9D2449;
  --error: #DD0000;
  --primary: #18384e;
  --accent: #ff9f2d;
}
.primary-text { color: var(--primary); }
.accent-text { color: var(--accent); }
.primary { background-color: var(--primary); color: white; }
* { font-family: 'Montserrat'; }
body, #app { min-height: 100vh; }
body.loading, body.loading * { cursor: wait !important; }

.flex-row, .flex-column, .flex-row-reverse { display: flex; }
.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-column { flex-direction: column; }
.wrap { flex-wrap: wrap; }
.grow { flex-grow: 1; }

.full-width { width: 100%; }
.center-center { justify-content: center; align-items: center; }
.center-stretch { justify-content: center; align-items: stretch; }
.start-start   { justify-content: start; align-items: start; }
.start-center  { justify-content: start; align-items: center; }
.end-start     { justify-content: end; align-items: start; }
.end-center    { justify-content: end; align-items: center; }
.space-between-start { justify-content: space-between; align-items: start; }
.space-between-center { justify-content: space-between; align-items: center; }
.align-start   { align-self: start; }
.flex-row.gap-20 > * + *,
.flex-row-reverse.gap-20 > * + * { margin-left: 20px;}
.flex-column.gap-20 > * + * { margin-top: 20px;}

.flex-row.gap-10 > * + *,
.flex-row-reverse.gap-10 > * + * { margin-left: 10px;}
.flex-column.gap-10 > * + * { margin-top: 10px;}

/* boostrap tweaks */
hr.gob { opacity: 1; background-color: transparent; border-top: 1px solid #DDD; }
hr.gob:before {
  width: 35px;
  height: 5px;
  background-color: #B38E5D;
  position: absolute;
  display: block;
  content: " ";
  opacity: 1;
}
#app .alert-info { background-color: #d9edf7; color: #007ab7; }
#app .invalid-feedback { font-size: 0.8rem; margin-top: 0; }
#app .col-form-label { padding-bottom: 0; }
.field { display: block; height: 90px; }

[list]::-webkit-calendar-picker-indicator { display: none !important; }
select.form-control,
select.form-control.is-valid,
select.form-control.is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-position-x: calc(100% - 12px);
  background-position-y: 50%;
  background-size: 8px 10px;
  background-repeat: no-repeat;
}
select.form-control.is-valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23198754' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
}
select.form-control.is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23dc3545' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
}

.btn.btn-gob { color: var(--gob-accent); border-color: var(--gob-accent); }
.btn.btn-gob.disabled { pointer-events: auto; cursor: not-allowed; }
.btn.btn-gob:hover,
.btn.btn-gob:focus { color: white; background-color: var(--gob-accent); }
.btn.btn-gob:focus { box-shadow: none; border: 2px solid black; }
button.close {
  padding: 0; border: 0; font-size: 1.5rem; font-weight: 700; line-height: 1; opacity: .5;
}

body .carousel-control-prev { justify-content: start; padding-left: 1rem; width: 6%; }
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2304c' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E") !important;
}

  
body .carousel-control-next { justify-content: end; padding-right: 1rem; width: 6%; }
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2304c' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.custom-radio > label,
.custom-checkbox > label { margin-left: 5px; }

.no-printer { display: block; }
.printer-only { display: none; }
@media print {
  .no-printer { display: none; }
  .printer-only { display: block; }
}

/*
hr.red { margin-bottom: 40px; }
.error, .hint { font-size: 12px; height: 0; }
.field:not(.radio) { height: 100px; display: block; }
.radio-item > input[type="radio"] { margin-left: 0; }
select.invalid, input.invalid { border-color: var(--error); }
select.invalid:focus, input.invalid:focus {
  border-color: var(--error);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px var(--error);
}
*/
</style>
