import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tiposTicket: [],
  },
  mutations: {
    SET_TIPOS_TICKET: (s, v) => s.tiposTicket = v,
  },
  actions: {
    getTiposTicket: ({ commit }) => axios.get('/midecc/tickets').then( r => {
      commit('SET_TIPOS_TICKET', r.data)
      return r.data
    }),
    getStaff: () => axios.get('/api/v1/cac/usuarios/').then( r => r.data ),
    assigment: (_, { usuario, solicitud }) =>
        axios.post('/api/v1/cac/asignacion/', { 'usuario':usuario, 'solicitud':solicitud }).then(r => r.data),
    getEstatus: (_,t) =>axios.get(`/api/v1/cac/estatus/?tipo=${t}&operacional=1`).then( r => r.data ),
    getTipoTicket: (_, id) => axios.get(`/midecc/tickets/${id}`).then( r => r.data ),
    getTickets: (_, params) => axios.get(`/api/v1/cac/solicitud`, { params }).then( r => r.data ),
    getTicket: (_, id) => axios.get(`/api/v1/cac/solicitud/${id}`).then( r => r.data ),
    getTicketMovimientos: (_, params) =>
      axios.get(`/api/v1/cac/detalle-movimiento/`, { params })
      .then(r => r.data),
    changeState: (_, d) => axios.post(`/api/v1/cac/detalle-movimiento/`, d).then( r => r.data),
    changeAttachmentData: (_, d) =>
      axios.post(`/api/v1/nube/archivos/${d.uuid}/criterios/`, d.criterios).then(r => r.data),
    getGEOTickets: (_, { tid, eid, tipos }) =>
      axios.get(`/midecc/tickets/${tid}/estados/${eid}/escuelas`, {
        params: { tipos: tipos.join() }
      }).then( r => r.data ),
    updateDocument: (_, { tid, doc, form }) =>
      axios.post(`/midecc/tickets/${tid}/documentos/${doc}/`, form),
    getStatsTickets: (_, { tid, eid }) => 
      axios.get(`/midecc/tickets/${tid}/estados/${eid}/stats`).then( r => r.data ),
    getRules: (_, { procedimiento, cct }) =>
      axios.post('/midecc/tickets/instalacion', { procedimiento, cct }).then(r => r.data),

    save: (_, formData) =>
      axios.post('/midecc/tickets/instalacion/create', formData)
  },
}
