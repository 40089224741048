<template>
  <div class="container">

    <h1>Visualización de mapas</h1>
    Selecciona y configura el mapa que deseas visualizar.

    <div class="d-flex tabs">
      <div :class="{ active: clasifView }" @click="setView('Clasificacion')">Clasificacíon</div>
      <div :class="{ active: servView }" @click="setView('Servicios')">Servicios</div>
      <div :class="{ active: ticketView }" @click="setView('ticketView')">Solicitudes</div>
      <div class="flex-grow-1"></div>
    </div>

    <div class="tab estados">
      <div class="d-flex justify-content-between">
        <b-checkbox v-model="cluster" class="cluster">Clusterización</b-checkbox>

        <div>
          <span>Tamaño de punto</span>
          <div class="size d-flex">
            <b-radio v-model="size" :value="1">1</b-radio>
            <b-radio v-model="size" :value="2">2</b-radio>
            <b-radio v-model="size" :value="3">3</b-radio>
            <b-radio v-model="size" :value="4">4</b-radio>
            <b-radio v-model="size" :value="5">5</b-radio>
          </div>
        </div>
      </div>

      <div v-if="servView" class="servicio d-flex align-items-center">
        Servicio:
        <b-select v-model="servicio" plain>
          <b-option v-for="s of servicios" :key="s.id" :value="s.nombre">
            {{ s.nombre }}
          </b-option>
        </b-select>
      </div>

      <div v-if="ticketView" class="tickets d-flex align-items-center">
        Tipo Solicitud:
        <b-select v-model="tipoTicket" plain>
          <b-option v-for="t of tiposTicket" :key="t.id" :value="t">
            {{ t.nombre }}
          </b-option>
        </b-select>
      </div>

      <details ref="estadosDetails">
        <summary>
          Estados: <span class="small">({{ estadosChoice.length }} seleccionados)</span>
        </summary>
        <b-checkbox-group
          v-model="estadosChoice" class="estados-checkbox"
          text-field="nombre" value-field="id"
          :options="estados">
        </b-checkbox-group>
      </details>

      <details open v-if="clasifView || ticketView" ref="clasifDetails">
        <summary>
          Clasificación: <span class="small">({{ clasificacion.length }} seleccionados)</span>
        </summary>
        <b-checkbox-group
          v-model="clasificacion" class="clasificacion-checkbox">
          <b-checkbox v-for="t of tiposEscuela" :key="t.id" :value="t.siglas">
            {{ t.siglas }} - {{ t.nombre }}
          </b-checkbox>
        </b-checkbox-group>
      </details>

      <b-button v-if="clasifView " variant="gob" @click="goClasificacion()">Ver mapa</b-button>
      <b-button v-if="servView" variant="gob" @click="goServicio()">Ver mapa</b-button>
      <b-button v-if="ticketView" variant="gob" @click="goTickets()">Ver mapa</b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data: () => ({
    view: 'Clasificacion',
    estadosChoice: [],
    clasificacion: [],
    cluster: true,
    servicio: 'Internet',
    tipoTicket: null,
    size: 3,
  }),
  mounted() {
    this.fetchEstados().then(
      () => this.estadosChoice = this.estados.map( e => e.id )
    )

    this.getTiposEscuela().then(
      () => this.clasificacion = this.tiposEscuela
        .filter(o => ['ETV', 'DTV', 'ETK', 'ETH'].includes(o.siglas) )
        .map(o => o.siglas)
    )

    this.getServicios().then()
    this.getTiposTicket().then( () => {
      this.tipoTicket = this.tiposTicket[0]
    })
  },
  computed: {
    ...mapState('encuesta', ['tiposEscuela', 'servicios']),
    ...mapState('geo', ['estados']),
    ...mapState('tickets', ['tiposTicket']),
    clasifView() { return this.view === 'Clasificacion' },
    servView() { return this.view === 'Servicios' },
    ticketView() { return this.view === 'ticketView' }
  },
  methods: {
    ...mapActions('encuesta', ['getTiposEscuela', 'getServicios']),
    ...mapActions('tickets', ['getTiposTicket']),
    ...mapActions('geo', ['fetchEstados']),
    setView(view) {
      this.view = view
      this.size = this.clasifView ? 3 : 2;
      this.cluster = this.clasifView
      this.$refs.estadosDetails.open = this.servView || this.ticketView
    },
    goClasificacion() {
      this.$router.push({ path: '/mapa/clasificacion', query: {
        cluster: this.cluster,
        size: this.size,
        tipos: this.clasificacion.length === this.tiposEscuela.length ?
          undefined :
          this.clasificacion.join(),
        estados: this.estados.length === this.estadosChoice.length ?
          undefined :
          this.estadosChoice.join(),
      }})
    },
    goServicio() {
      this.$router.push({ path: '/mapa/servicio', query: {
        cluster: this.cluster,
        nombre: this.servicio,
        size: this.size,
        estados: this.estados.length === this.estadosChoice.length ?
          undefined :
          this.estadosChoice.join(),
      } })
    },
    goTickets() {
      this.$router.push({ path: '/mapa/solicitudes', query: {
        ticket: this.tipoTicket.id,
        estados: this.estados.length === this.estadosChoice.length ?
          undefined :
          this.estadosChoice.join(),
        tipos: this.clasificacion.length === this.tiposEscuela.length ?
          undefined :
          this.clasificacion.join()
      } })
    }
  },
}
</script>

<style scoped>
.container { margin-top: 50px; }

.tabs { margin: 20px 0 10px 0; }
.tabs div { padding: 10px; border-bottom: 1px solid #CCC; cursor: pointer; }
.tabs div.active { border: 1px solid #CCC; border-bottom: none; }
.tabs div.flex-grow-1 { border: none; border-bottom: 1px solid #CCC; }

.size, details { margin-top: 5px; }

.estados-checkbox,
.clasificacion-checkbox {
  max-height: 300px; overflow: auto; border: 1px solid #CCC; padding: 5px;
}

.servicio { margin-top: 10px; }
.servicio .form-control { margin-left: 10px; width: 200px; }
.tickets { margin-top: 10px; }
.tickets .form-control { margin-left: 10px; width: 300px; }

.size > * { margin-right: 10px; }
.btn { margin: 20px auto; display: block; }
</style>
