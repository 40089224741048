import axios from 'axios'

const STATS_URL =
  (id, name) => `/midecc/tickets/tablero/globales/${id}/stats/${name}/`

export default {
    namespaced: true,
    state: {
        data: null,
    },
    actions:{
        fetchData: (_, params) => axios.get( '/midecc/tickets/tablero/LA-11-712-011000999-N-7-2023/', { params }).then( r => r.data ),
        usuarios:(_,params)=>axios.get('/midecc/tickets/movimientos/1/',{params}).then(r=>r.data),
        globales:(_,params)=>axios.get('/midecc/tickets/tablero/globales/1/',{params}).then(r=>r.data),
        eestados:(_, {id})=>axios.get(`/midecc/tickets/tablero/estados/1/${id}/`).then(r=>r.data),
        lestados:(_,params) => axios.get('api/general/catalogos/estados/',{params}).then(r=>r.data),
        getInstalacionEscuelaStats: () => {
          const url = STATS_URL(1, 'solicitud-escuela')
          return axios.get(url).then(r => r.data)
        },
        getDecodificadorStats: () => {
          const url = STATS_URL(1, 'decodificador')
          return axios.get(url).then(r => r.data)
        },
        getTotalDecodificadores: () => {
          const url = STATS_URL(1, 'decodificadores')
          return axios.get(url).then(r => r.data)
        },
        getTotalRegresosPendiente: () => {
          const url = '/midecc/tickets/tablero/globales/isidoro/stats/regresos-pendiente/'
          return axios.get(url).then(r => r.data)
        }
    },

}
