<template>
  <div class="map-wrapper">
    <mapbox-map ref="mapComponent"></mapbox-map>
    <h4 class="title" v-if="tipo">{{ title }}</h4>

    <div class="info">
      <div v-if="showBtns&&!isRenovacionTecnologica" class="btns">
        <div class="estatus d-flex flex-column">
          <div style="color:black;text-align:center;">
            <b>Estatus:</b>
          </div>
          <template v-for="e of ticketStates">
            <div v-if="!e.hide" :key="e.id"
              class="d-flex center-center stat-item"
              :title="title"
              @click="toggleLayers($event.target, '^' + e.key + '_')">
                <b-icon icon="circle-fill" style="pointer-events:none"
                  :style="{ color: e.color }">
                </b-icon>
                {{ e.nombre }}
            </div>
          </template>
        </div>

        <div class="tipos-title">
          <b>Tipo de organización:</b>
        </div>
        <div class="d-flex flex-column tipos-organizacion">
          <template v-for="t of tipos">
            <button v-if="t.siglas !== 'DTV' && t.siglas !== 'ETV'" :key="t.siglas"
              :style="{ background: 'var(--gob-primary-light)' }"
              @click="toggleLayers($event.target, '_' + t.siglas + '_')">
              <template v-if="t.siglas === 'ETK'">Telebachilleratos Federales</template>
              <template v-else-if="t.siglas === 'ETH'">Telebachilleratos Estatales</template>
              <template v-else>{{ t.nombre }}</template>
            </button>
            <button v-else-if="t.siglas === 'DTV'" :key="t.siglas"
              :style="{ background: 'var(--gob-primary-light)' }"
              @click="toggleTelebachilleratos($event.target)"> Telesecundarias
            </button>
          </template>
        </div>
        <div ref="hiddenElement"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MapboxMap from './MapboxMap.vue'

export default {
  components: { MapboxMap },
  data: () => ({
    tipo: null,
    mapComponent: null,
    popup: null,
    cluster: true,
    showBtns: false,
    size: 3,
    layers: [],
    stats: {},
    intervalRef: null,
    estadoIds: [],
    ticket: null,
    tipos: [],
    groupLayerHidden: []
  }),
  unmounted() {
    this.stopLiveUpdate()
  },
  async mounted() {
    // let { estados, nombre, cluster, size } = this.$route.query
    let { ticket, tipos, size, cluster, estados } = this.$route.query

    if (this.tiposEscuela.length === 0) { await this.getTiposEscuela() }

    if (tipos) {
      tipos = tipos.split(',')
      this.tipos = this.tiposEscuela.filter(t => tipos.includes(t.siglas))
    } else {
      if (this.isRenovacionTecnologica) {
        this.tipos = this.tiposEscuela.filter(t => [
          'ETV', 'DTV', 'ETK', 'ETH'
        ].includes(t.siglas))
      } else {
        this.tipos = [...this.tiposEscuela]
      }
    }

    // Special treatment for public access
    if (this.isRenovacionTecnologica) {
      ticket = 1
    }

    this.getTipoTicket(ticket)
      .then( t => this.tipo = t )
      .catch( () => this.$router.push({ path: '/' }) )

    this.size = Number(size) || 2
    this.cluster = cluster === 'true'
    this.ticket = ticket

    if (!estados && this.estados.length === 0) { await this.fetchEstados() }
    this.estadoIds = estados ? estados.split(',') : this.estados.map(e => e.id)
    this.mapComponent = this.$refs.mapComponent
    this.mapComponent.loadMap(() => this.loadData())
  },
  beforeRouteLeave(to, from, next) {
    this.stopLiveUpdate()
    next()
  },
  computed: {
    ...mapState('geo', ['estados']),
    ...mapState('encuesta', ['tiposEscuela']),
    ticketStates() {
      return this.isRenovacionTecnologica ? [
        { nombre: 'Apagado', color: '#666666', key: 'unchecked' },
        { nombre: 'Encendido', color: '#00FF00', key: 'unsolved' },
        { nombre: 'Encendido', color: '#00FF00', key: 'solved' },
      ] : [
        { nombre: 'Apagado', color: '#666666', key: 'unchecked' },
        { nombre: 'Notificado', color: '#FF4400', key: 'unsolved' },
        { nombre: 'Verificado', color: '#00FF00', key: 'solved' },
      ]
    },
    /**
     * Special case treatment
     */
    isRenovacionTecnologica() {

      return this.$route.path === '/teleplanteles/renovacion-tecnologica'
    },
    title() {
      return this.isRenovacionTecnologica ?
        'Renovación Tecnológica de Teleplanteles' :
        ` ${this.tipo.descripcion}`
    }
  },
  methods: {
    ...mapActions('tickets', ['getTipoTicket', 'getGEOTickets', 'getStatsTickets']),
    ...mapActions('geo', ['fetchEstados']),
    ...mapActions('encuesta', ['getTiposEscuela']),

    // ...mapActions('escuela', ['fetchGeoReferencesIn']),
    // ...mapActions('encuesta', ['getEstadosServicio', 'getServicios']),
    loadData() {
      // TODO Handle support for CCTs types
      const tipos = this.tipos.map(t => t.siglas)

      Promise.all(this.estadoIds.map(
        eid => this.getStatsTickets({ tid: this.ticket, eid }).then( stats => {
          this.stats[eid] = stats
          return this.getGEOTickets({ tid: this.ticket, eid, tipos }).then( geoRefsEscuelas => {
            Object.entries(geoRefsEscuelas).forEach(([tipo, geoRefsTickets]) => {

              if (this.isRenovacionTecnologica) {
                geoRefsTickets.solved.features.push(
                  ...geoRefsTickets.unsolved.features.map( f => f )
                )
                delete geoRefsTickets.unsolved
              }

              Object.entries(geoRefsTickets).forEach( ([key, featureCollection]) => {
                const prefix = `${key}_${tipo}_${eid}_`

                const { color } = this.ticketStates.find(ts => ts.key === key) || {}

                this.mapComponent.addSource(prefix, {
                  type: 'geojson',
                  data: {
                    ...featureCollection,
                    features: featureCollection.features.map( f => ({
                      ...f, properties: { ...f.properties, tipo, key, eid }
                    }))
                  },
                  cluster: this.cluster,
                  clusterMaxZoom: 15,
                  clusterRadius: 50,
                })
                this.mapComponent.addLayer(prefix, prefix + 'unclustered', {
                  type: 'circle',
                  filter: ['!', ['has', 'point_count']],
                  paint: {
                    'circle-color': color,
                    'circle-radius': this.size,
                    'circle-stroke-width': this.cluster ? 1 : 0,
                    'circle-stroke-color': '#fff'
                  }
                }, {
                  click: e => this.onUnClusteredClick(e),
                  mouseenter: ({ map }) => map.getCanvas().style.cursor = 'pointer',
                  mouseleave: ({ map }) => map.getCanvas().style.cursor = '',
                })

                this.layers.push(prefix + 'unclustered')
                if (!this.cluster) { return }

                this.mapComponent.addLayer(prefix, prefix + 'clustered', {
                  type: 'circle',
                  filter: ['has', 'point_count'],
                  paint: {
                    'circle-color': [
                      'step', ['get', 'point_count'],
                      color, 50,
                      color, 200,
                      color
                    ],
                    'circle-radius': [
                      'step', ['get', 'point_count'],
                      20, 50,
                      30, 200,
                      40
                    ],
                  },
                }, { click: e => this.onClusterClick(e) })
                this.layers.push(prefix + 'clustered')
                this.mapComponent.addLayer(prefix, prefix + 'count', {
                  type: 'symbol',
                  filter: ['has', 'point_count'],
                  layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                  },
                  paint: { 'text-color': '#fff' }
                })
                this.layers.push(prefix + 'count')
              })
            })
            /*
            */
          })
        })
      )).then( () => {
        this.showBtns = true
        this.setLiveUpdate()
      })
    },
    setLiveUpdate() {
      this.intervalRef = window.setInterval( () =>
        this.estadoIds.forEach(
          eid => this.getStatsTickets({ tid: this.ticket, eid }).then( stats => {
            // Short circuit when nothing changed
            const changed = stats.unchecked !== this.stats[eid].unchecked ||
              stats.unsolved !== this.stats[eid].unsolved
              stats.solved !== this.stats[eid].solved

            if (!changed) { return }

            this.getGEOTickets({ tid: this.ticket, eid }).then( featureCollections => {
              Object.entries(featureCollections).forEach( ([key, featureCollection]) => {
                const prefix = `${key}_${eid}_`
                this.mapComponent.getSource(prefix).setData(featureCollection)
              })
              this.stats[eid] = stats
            })
          })
        )
      , 5000)
    },
    stopLiveUpdate() {
      window.clearInterval(this.intervalRef)
    },
    onClusterClick({ map, source, feature }) {
      source.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
        if (err) return;
        map.easeTo({ center: feature.geometry.coordinates, zoom });
      });
    },
    onUnClusteredClick({ map, feature, Mapbox, layerId }) {
      const { coordinates } = feature.geometry
      const { CCT, nombre, ticket_id, key } = { CCT: 'CCT', nombre: '...', ...feature.properties }
      let tableHTML = '<table  class="table table-striped" style="width: 220px">';
      if (feature.properties['data']) {
        let ss = JSON.parse(feature.properties['data'])
        for (let id in  ss) {
          tableHTML += `<tr><td>${ss[id]['name']}</td><td>${ss[id]['value']}</td></tr>`;
        }
      }

      tableHTML += '</table>';
      this.popup = new Mapbox.Popup({ layer: layerId })
        .setLngLat(coordinates)
        .setHTML(
          `<b>CCT:</b> ${CCT} <br>`+
          `<b>Nombre:</b> <a href="/escuelas/${CCT}">${nombre}</a> <br>` + (
            ticket_id ?
              (this.isRenovacionTecnologica ?
                `<b>Id Solicitud:</b> ${ticket_id} <br>` :
                `<b>Id Solicitud:</b> <a href="/tickets/${ticket_id}">${ticket_id}</a><br>`
              ) :
              '<b>Id Solicitud:</b> N/A <br>'
          ) + (
            '<b>Estado: </b>' +
            (this.ticketStates.find(s => s.key === key) || {}).nombre +
            '<br>'
          ) + tableHTML
        )
        .addTo(map)
    },
    toggleTelebachilleratos(el) {
      this.toggleLayers(el, '_DTV_')
      setTimeout(() => this.toggleLayers(this.$refs.hiddenElement, '_ETV_'), 50)
    },
    toggleLayers(el, prefix) {
      // If the actual state is hidden, then try to show it
      const show = el.style.opacity === '0.5'
      el.style.opacity = show ? '1' : '0.5'

      if (show) {
        this.groupLayerHidden = this.groupLayerHidden.filter(
          p => p !== prefix
        )
      } else {
        this.groupLayerHidden = [...this.groupLayerHidden, prefix]
      }

      this.layers.forEach(l => {
        if (show) {
          const whiteListed = this.groupLayerHidden.reduce(
           (acc, h) => acc && !l.match(h), true
          )
          if (whiteListed) {
            this.mapComponent.setLayerVisibility(l, 'visible')
          }
        } else {
          if (l.match(prefix)) {
            this.mapComponent.setLayerVisibility(l, 'none')
          }
        }
      })
    },
    exists(prefix) {
      return this.layers.find( l => l.includes(prefix) )
    },
  }
}
</script>

<style scoped>
.tpop{
  width: 200px;
}
.map-wrapper { position: relative; height: 100vh; }
.info { position: absolute; top: 0; right: 0; margin: 15px; }
.title {
  position: absolute; top: 0; lefT: 0;
  margin-top: 15px; padding: 10px 20px;
  color: white;
  background: var(--gob-accent);
  font-weight: bold;
}
button {
  color: white;
  font-weight: bold;
  font-size: 80%;
  padding: 10px;
  border: none;
}

#mapContainer { position: absolute; top: 0; bottom: 0; width: 100%; }
.mapboxgl-canvas-container { height: 100% !important; }
.mapboxgl-canvas-container canvas { height: 100% !important; }

.btns {
  margin-top: 10px;
  max-width: 300px;
}
.estatus { padding: 0.5rem; background: #FFFFFFCC; }
.tipos-organizacion {
  max-height: 300px;
  overflow: auto;
}
.tipos-title {
  background: #FFFFFFCC;
  color: black;
  margin-top: 1rem;
  text-align: center;
}
.stat-item { gap: 5px; cursor: pointer; }

</style>
