import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import routes from './main.routes'
import VueRouter from 'vue-router'
import store from './main.store'
import BootstrapVueForms from './plugins/BootstrapVueForms'
import HttpInterceptors from './plugins/HttpInterceptors'
import VeeValidate from './plugins/VeeValidate'
import {
  BIcon,
  BModal,
  BAlert,
  BProgressBar,
  BProgress,
  BCarousel,
  BCarouselSlide,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Autocomplete from './components/Autocomplete'
import Uppercase from './directives/Uppercase'
import Lowercase from './directives/Lowercase'
import Digits from './directives/Digits'
import { DateFilter, CountFilter } from './filters/filters'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VeeValidate)
Vue.use(BootstrapVueForms)
Vue.use(IconsPlugin)

Vue.directive('digits', Digits)
Vue.directive('uppercase', Uppercase)
Vue.directive('lowercase', Lowercase)

Vue.filter('date', DateFilter)
Vue.filter('count', CountFilter)

Vue.component('b-icon', BIcon)
Vue.component('b-alert', BAlert)
Vue.component('b-modal', BModal)
Vue.component('b-progress-bar', BProgressBar)
Vue.component('b-progress', BProgress)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-textarea', BFormTextarea)

Vue.component('autocomplete', Autocomplete)
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const $store = new Vuex.Store(store)
Vue.use(HttpInterceptors($store))

const access_token  = window.localStorage.getItem('access_token')
const refresh_token = window.localStorage.getItem('refresh_token')
if (access_token && access_token !== 'undefined' &&
    refresh_token && refresh_token !== 'undefined'
) {
  $store.state.access_token  = access_token
  $store.state.refresh_token = refresh_token
  $store.dispatch('fetchUserInfo')
}


new Vue({
  router,
  store: $store,
  render: h => h(App),
}).$mount('#app')
