<template>
  <div class="container">
    <ValidationObserver v-slot="{ invalid }" v-if="!tipo_solicitud"
      class="access-form d-flex flex-column justify-center">

      <h1 class="text-center">Teleplanteles <br/> {{ procedimiento.titulo }}</h1>
      <ValidationProvider class="field" rules="required">
        <b-form-group label="Procedimiento">
          <b-input v-model="procedimiento" maxLength="30" v-uppercase value="ENCUESTA_TELEPLANTELES_2024" readonly></b-input>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" rules="required">
        <b-form-group label="CCT">
          <b-input v-model="escuela" maxLength="10" v-uppercase></b-input>
        </b-form-group>
      </ValidationProvider>

      <b-button variant="gob" :disabled="invalid" squared
        @click="validate()">Acceder
      </b-button>
      <div v-if="err" class="text-center err">{{ err }}</div>

      <template v-if="solicitudFound">
        <RouterLink class="text-center"
          :to="'/tickets/' + solicitudFound.id">
            {{ solicitudFound.titulo }}
            (#{{ solicitudFound.id }})<br>
            {{ solicitudFound.estatus.nombre }}
        </RouterLink>
      </template>
    </ValidationObserver>

    <ValidationObserver v-else v-slot="{ invalid }">

      <h1>Encuesta 2024</h1>

      <small class="d-flex justify-content-end red">
        * Campos requeridos
      </small>

      <div class="flex-row gap-20 head-form">
        <b-form-group label="ID">
          <b-input v-model="procedimiento" disabled></b-input>
        </b-form-group>

        <b-form-group label="CCT">
          <b-input v-model="escuela" disabled ref="text_escuela"></b-input>
        </b-form-group>
      </div>

      <div v-for="(field, i) of formFields" :key="'f'+i">
        <div v-if="field.type === 'GeoData'">
          <Domicilio v-model="fields[field.name]"
            @update:valid="states[field.name] = $event"
            :hideButtons="true"
            :perimeterValidation="false"/>
        </div>
        <ValidationProvider v-else
          class="field" :rules="field.rules" v-slot="v">
          <b-form-group>
            <template #label>
              {{ field.label }}<span v-if="isRequired(field)" class="red">*</span>
            </template>
            <b-input v-model="fields[field.name]" :maxLength="field.maxLength" :state="$state(v)" :type="field.type?field.type:'text'"></b-input>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
          </b-form-group>
        </ValidationProvider>
      </div>

      <ValidationProvider v-for="(field, i) of formAttachments" :key="'a'+i" :rules="field.rules" class="file-control">
        {{ field.label }}<span v-if="isRequired(field)" class="red">*</span>
        <b-form-file v-model="attachments[field.name]" :accept="field.mime" class="form-control"
          placeholder="" plain>
        </b-form-file>
      </ValidationProvider>

      <div class="flex-row space-between-start gap-20">
        <b-button @click="reset()" variant="gob" squared>Regresar</b-button>
        <b-button @click="create()" variant="gob" :disabled="invalid || !geoValid" squared>Enviar</b-button>
      </div>
    </ValidationObserver>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Domicilio from '@/modules/encuesta/Domicilio'
export default {
  components: { Domicilio },
  data: () => ({
    procedimiento: 'ENCUESTA_TELEPLANTELES_2024',
    escuela: '',
    solicitudFound: null,
    tipo_solicitud: null,
    err: null,
    fields: {},
    states: {},
    attachments: {},
  }),
  mounted() {
    const { procedimiento, cct } = this.$route.query
    this.procedimiento = procedimiento
    this.escuela = cct
  },
  computed: {
    formFields() {
      if (!this.tipo_solicitud) {
        return []
      }
      return this.tipo_solicitud.metadatos.fields || []
    },
    formAttachments() {
      if (!this.tipo_solicitud) {
        return []
      }
      return this.tipo_solicitud.metadatos.attachments || []
    },
    geoValid() {
      return this.formFields
        .filter( f => f.type === 'GeoData' )
        .reduce( (state, f) => state && this.states[f.name], true)
    }
  },
  methods: {
    ...mapActions('tickets', ['getRules', 'save', 'getTickets']),
    ...mapActions('escuela', ['fetchDomicilio', 'fetchEscuela']),
    isRequired(field) {
      return field.rules && field.rules.includes('required')
    },
    validate() {
      Promise.all([
        this.getRules({
          procedimiento: this.procedimiento,
          cct: this.escuela,
        }),
        this.fetchEscuela(this.escuela).then(
          () => this.fetchDomicilio()
        ),
      ]).then( ([tipo_solicitud]) => {
        const params = {
          tipo: tipo_solicitud.id,
          page: 1,
          size: 1,
          search: this.escuela
        }
        return Promise.all([
          this.getTickets(params),
          this.getTickets({ ...params, cerrado: true }),
        ]).then( ([resp1, resp2]) => {
          // Si ya existe una entonces no puede ocupar este formulario
          if (resp1.count > 0 && tipo_solicitud.metadatos?.updatable === false ){
            this.err = 'Ya existe una Solicitud de instalacion para esta escuela'
            this.solicitudFound = resp1.results[0]
            return
          }
          if (resp2.count > 0 && tipo_solicitud.metadatos?.updatable === false ) {
            this.err = 'Ya existe una Solicitud de instalacion para esta escuela'
            this.solicitudFound = resp2.results[0]
            return
          }

          this.fields = tipo_solicitud.metadatos.fields.reduce((acc, v) => {
            acc[v.name] = v.type === 'GeoData' ? { valid: null } : null
            return acc
          }, {})
          this.states = tipo_solicitud.metadatos.fields.reduce((obj, f) => {
            if (f.type === 'GeoData') {
              obj[f.name] = false
            }
            return obj
          }, {})
          this.attachments = tipo_solicitud.metadatos.attachments.reduce((acc, v) => {
            acc[v.name] = null
            return acc
          }, {})
          this.tipo_solicitud = tipo_solicitud
        })
      }).catch( err => {
        console.log(err)
        if (err.response.status == 404){
          this.err = "CCT no encontrada"
        } else{
          this.err = 'Acceso denegado'
        }

      })
    },
    reset() {
      this.escuela = ''
      this.procedimiento = 'ENCUESTA_TELEPLANTELES_2024'
      this.tipo_solicitud = null
      this.fields = {}
      this.attachments = {}
    },
    create() {
      const formData = new FormData()
      formData.set('tipo_solicitud', this.tipo_solicitud.id)
      formData.set('status', this.tipo_solicitud.metadatos.initial_state)
      formData.set('procedimiento', this.procedimiento)
      formData.set('cct', this.escuela)
      this.formFields.forEach( f => {
        if (typeof this.fields[f.name] === 'object') {
          formData.set(f.name, JSON.stringify(this.fields[f.name]))
        } else {
          formData.set(f.name, this.fields[f.name])
        }
      })
      this.formAttachments.forEach( a => {
        const file = this.attachments[a.name]
        if (file) {
          const ext = file.name.replace(/.*\./, '')
          formData.set(a.name, this.attachments[a.name], `${this.escuela}-${a.name}.${ext}`)
        }
      })

      this.save(formData).then( () => {
        alert('Se ha registrado tus datos de la encuesta.')
        window.location.reload()
      }).catch( () => {
        alert('Error en el envio de datos')
      })
    }
  },
}
</script>

<style scoped>
.access-form { width: 300px; margin: 0 auto; }
.container { margin-top: 40px; margin-bottom: 40px; }
.err { color: var(--gob-accent); font-size: small; padding: 10px 0; }
.head-form { margin-bottom: 20px; }
.file-control { margin-bottom: 1.5rem; display: block; }
.red { color: var(--gob-accent); }
</style>
