import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'
import { BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BFormFile } from 'bootstrap-vue'
import { BFormCheckboxGroup, BFormCheckbox, BFormRadio, BFormRadioGroup } from 'bootstrap-vue'
// import { BTabs, BTab } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { VBTooltip, BTable } from 'bootstrap-vue'

export default {
  install(Vue) {
    Vue.component('b-form-group', BFormGroup)
    Vue.component('b-select', BFormSelect)
    Vue.component('b-option', BFormSelectOption)
    Vue.component('b-radio', BFormRadio)
    Vue.component('b-radio-group', BFormRadioGroup)
    Vue.component('b-checkbox', BFormCheckbox)
    Vue.component('b-checkbox-group', BFormCheckboxGroup)
    Vue.component('b-input', BFormInput)
    Vue.component('b-textarea', BFormTextarea)
    Vue.component('b-error', BFormInvalidFeedback)
    Vue.component('b-button', BButton)
    Vue.component('b-form-file', BFormFile)
    Vue.component('b-table', BTable)

    // Vue.component('b-tab', BTab)
    // Vue.component('b-tabs', BTabs)

    Vue.directive('btooltip', VBTooltip)
    Vue.prototype.$state = v => (v.touched || v.dirty) ? v.valid : null
  }
}
