import axios from 'axios'

const getEscuelaData = ({ commit, state }, attr, mutation) => axios
    .get(`/midecc/escuelas/${state.escuela.clave}/${attr}`).then( resp => {
        commit(mutation, resp.data)
        return resp.data
    })

export default {
  namespaced: true,
  state: {
    escuela: null,
    domicilio: {},
    plantilla: {},
    servicios: [],
    equipamiento: [],
    encuesta: {},
  },
  mutations: {
    SET_ESCUELA:   (s, v) => s.escuela = v,
    SET_DOMICILIO: (s, v) => s.domicilio = v,
    SET_PLANTILLA: (s, v) => s.plantilla = v,
    SET_SERVICIOS: (s, v) => s.servicios = v,
    SET_EQUIPAMIENTO: (s, v) => s.equipamiento = v,
    SET_ENCUESTA: (s, v) => s.encuesta = v,
  },
  actions: {
    fetchEscuelas: (_, params) => axios.get('/midecc/escuelas', { params }).then( r => r.data.results ),
    fetchEscuela: ({ commit }, clave) => axios.get(`/midecc/escuelas/${clave}`).then( resp => {
      commit('SET_ESCUELA', resp.data);
      return resp.data;
    }),
    fetchDomicilio: (ctx) => getEscuelaData(ctx, 'domicilio', 'SET_DOMICILIO'),
    fetchPlantilla: (ctx) => getEscuelaData(ctx, 'plantilla', 'SET_PLANTILLA'),
    fetchServicios: (ctx) => getEscuelaData(ctx, 'servicios', 'SET_SERVICIOS'),
    fetchEquipamiento: (ctx) => getEscuelaData(ctx, 'equipamiento', 'SET_EQUIPAMIENTO'),
    fetchLastEncuesta({ commit, state }) {
      const url = `/midecc/encuestas/${state.escuela.clave}/last`
      return axios.get(url).then( resp => {
        commit('SET_ENCUESTA', resp.data)
        return resp.data
      })
    },
    fetchAllEscuelaData({ dispatch }, clave) {
      return dispatch('fetchEscuela', clave).then( () =>
        Promise.all([
          dispatch('fetchDomicilio'),
          dispatch('fetchPlantilla'),
          dispatch('fetchEquipamiento').catch( () => {} ),
          dispatch('fetchServicios')
        ])
      )
    },
    exists: (_, clave) => axios.get(`/midecc/escuelas/${clave}`)
      .then( () => true)
      .catch( err => {
        if (err.response && err.response.status === 404) {
          return false
        }
        throw err
      }),
    fetchGeoReferencesIn(ctx, { eid, tipos = [], servicio }) {
      return axios.get(`/midecc/estados/${eid}/escuelas`, {
        params: { tipos: tipos.join(), servicio }
      }).then( resp => resp.data )
    }
  },
}
