<template>
  <div v-if="ticket" class="container ticket-content" :class="{ expanded }">

    <span class="go-back no-printer" @click="back()">
      <b-icon icon="arrow-left"></b-icon>
      Regresar
    </span>
    <div class="no-printer">
      <div class="d-flex justify-content-between">
        <h4>
          {{ ticket.titulo }}
          <span class="state"
            :style="{ backgroundColor: ticket.estatus.color }">
            {{ ticket.estatus.nombre }}
          </span>
        </h4>
        <div class="no-printer">
          <b-button variant="outline-secondary" outlined size="sm"
            @click="expanded = !expanded">
            <b>{{ expanded ? 'Contraer' : 'Expandir' }}</b>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Header -->
    <div class="no-printer">
      <div class="tabs d-flex">
        <div :class="{ selected: ticketView === 'detalle' }"
          @click="ticketView = 'detalle'">Detalle</div>
        <div :class="{ selected: ticketView === 'documentos' }"
          @click="ticketView = 'documentos'">Documentos</div>
        <div v-if="isStaff || isWatcher" :class="{ selected: ticketView === 'ubicacion' }"
          @click="ticketView = 'ubicacion'">Ubicación
        </div>
        <div :class="{ selected: ticketView === 'movimientos' }"
          @click="ticketView = 'movimientos'">Movimientos
        </div>
        <template v-if="isStaff || isWatcher">
          <div :class="{ selected: ticketView === 'escuela' }"
               @click="ticketView = 'escuela'">Datos de la escuela
          </div>
          <div :class="{ selected: ticketView === 'asignacion' }"
               @click="ticketView = 'asignacion'">Asignación de solicitud
          </div>
          <div :class="{ selected: ticketView === 'print' }"
            @click="ticketView = 'print'">Imprimir
          </div>
        </template>
      </div>
    </div>

    <!-- Detalle -->
    <div v-if="ticketView === 'detalle'">
      <div v-if="ticket.metadatos">
        <b>Escuela</b>:
        <a v-if="isStaff" target="_blank" class="mr-3"
          :href="'/escuelas/' + ticket.metadatos.cct">{{ ticket.metadatos.cct }}</a>
        <span v-else>{{ ticket.metadatos.cct }}</span>
      </div>
      <div>
        <b>Contenido</b>
        <pre>{{ ticket.contenido }}</pre>
      </div>
      <div>
        <b>Creado</b>
        <pre>{{ ticket.creado | date }}</pre>
      </div>
      <div v-for="(field, i) in ticket.tipo.metadatos.fields" :key="'f' + i">
        <div v-if="field.type === 'GeoData'">
          <b>{{ field.label }}</b>
          <pre>{{ ticket.metadatos[field.name] }}</pre>
        </div>
      </div>
      <div>
        <b>Archivos</b>
        <ul>
          <li v-for="(item, i) in attachments" :key="'a' + i" class="file-data">
            <a v-if="item.attach" target="_blank"
              :href="item.attach.archivo">{{ item.metadata.label }}
            </a>
            <span v-else class="stroked">
              {{ item.metadata.label }}
              <b-icon v-if="item.metadata.updatable && isStaff"
                icon="upload" class="ms-2"
                @click="chooseFile(item.metadata)">
              </b-icon>
            </span>
          </li>
        </ul>
        <!-- HIDDEN -->
        <b-modal v-model="fileUpload.modal" title="Subir archivo"
          :ok-disabled="!fileUpload.value" @ok="setFile()"
          @cancel="fileUpload.value = null">
          <div><b>{{ fileUpload.label }}</b></div>
          <b-form-file v-model="fileUpload.value" class="form-control"
            ref="finput" placeholder="" plain
            :accept="fileUpload.mime">
          </b-form-file>
        </b-modal>
      </div>
    </div>

    <!-- Documentos -->
    <div v-show="ticketView === 'documentos'">
      <b-carousel v-if="hasAttachments" class="documentos"
        v-model="slide" :interval="0" controls indicators
        label-next="" label-prev="">
        <template v-for="(item, i) in attachments" >
          <b-carousel-slide v-if="item.attach" :key="i">
            <template #img>
              <Attachment class="slide"
                :num="i + 1"
                :metadata="item.metadata"
                :attach="item.attach"
                :reader="!isStaff"
                @change="changeArchivoData(item.metadata.name, $event)"
                @document="changeDocument(item.metadata.name, $event)">
              </Attachment>
            </template>
          </b-carousel-slide>
        </template>
      </b-carousel>
      <div v-else class="text-center no-attachments">
        No hay documentos
      </diV>
    </div>

    <!-- Ubicacion -->
    <div v-show="ticketView === 'ubicacion'" class="ubicacion">
      <b>Referencia Geográfica:</b>
      <div ref="map" class="map"></div>
      <b>Latitud:</b> <span v-if="ticket.metadatos">{{ ticket.metadatos.lat }}</span><br>
      <b>Longitud:</b> <span v-if="ticket.metadatos">{{ ticket.metadatos.lng }}</span><br>
    </div>

    <!-- Movimientos -->
    <div v-if="ticketView === 'movimientos'" class="movimientos">
        <div v-if="isStaff" class="row cambio">
            <div class="col col-4">
                Agregar estatus/incidencia
            </div>
            <div class="col col-4">
                <b-form-select
                        v-model="movimiento.estatus"
                        value-field="id"
                        text-field="nombre"
                        :options="estatus"
                        class="custom-select"
                >
                </b-form-select>
                <b-form-textarea
                        id="textarea"
                        v-model="movimiento.contenido"
                        placeholder="Contenido..."
                        rows="3"
                        max-rows="6"
                ></b-form-textarea>

            </div>
            <div class="col col-4">
                <b-button variant="danger"
                          @click="cambiarEstado(movimiento)">
                    Cambiar estatus
                </b-button>
            </div>
        </div>
      <div class="mb-2">
        <b>Regresos a Pendiente</b>:
        {{ ticket.error_a_pendiente }}
      </div>
      <details v-for="m in movimientos" :key="'m' + m.id" :title="m.autor">
        <summary>[{{ m.estatus.nombre }}]: {{ m.contenido }}</summary>
        <div class="detail">
          Fecha: {{ m.creado || '-' }} <br>
          Autor: {{ m.autor || '-' }} <br>
        </div>
      </details>
      <div v-if="movimientos.length === 0" class="text-center no-movements">
        No hay movimientos registrados aún
      </div>
    </div>

    <!-- Escuela -->
    <div v-if="ticketView === 'escuela' && escuela">
      <div>Clave:
        <a :href="'/escuelas/' + escuela.clave">
          {{ escuela.clave }}
        </a>
      </div>
      <div>Aulas: {{ escuela.aulas }}</div>
      <template v-if="equipamiento">
        <b>Equipamiento:</b>
        <div v-for="(e, i) of equipamiento" :key="'e' + i">
          <span v-if="e">
            - {{ e.nombre }} ({{ e.clasificacion }})
            <small>
              {{ e.funcionales }} funcionales,
              {{ e.coninternet }} con internet,
              {{ e.descompuesto }} descompuestos
            </small>
          </span>
        </div>
      </template>
      <template v-if="servicios">
        <b>Servicios:</b>
        <div v-for="(s, i) of servicios" :key="'s' + i">
          <span v-if="s">
            - {{ s.nombre }} ({{ s.estado.nombre }})
          </span>
        </div>
      </template>
    </div>
    <!-- Asignación -->
    <div v-if="ticketView === 'asignacion'">
        <div class="row">
            <div class="col col-3">

                {{ticket.asignado?"Asignado":"Sin Asignar" }}
            </div>
            <div class="col col-6">
                <b-form-select
                        v-model="ausuario"
                        value-field="id"
                        text-field="email"
                        :options="usuarios"
                        class="custom-select"
                        :disabled="isWatcher"
                >
                </b-form-select>


            </div>
            <div class="col col-3">
                <b-button variant="danger" :disabled="isWatcher"
                          @click="sendAssigment()">
                    Asignar a operador
                </b-button>
            </div>
        </div>
    </div>
    <!-- print -->
    <div v-if="ticketView === 'print'">
      <TicketPrint :ticket="ticket"></TicketPrint>
    </div>

    <!-- Footer -->
    <div v-if="isStaff" class="no-printer">
      <div v-if="ticket.tipo.metadatos.error_state && !closed">
        <hr/>
        <b>Errores</b> (Observaciones en caso de error)
        <b-textarea v-model="errors" class="errors"></b-textarea>
        <b-button variant="danger" :disabled="!errors"
          @click="showConfirmation(ticket.tipo.metadatos.error_state)">
          Mandar errores
        </b-button>
      </div>
      <div v-if="closed">
        <hr/>
        <b>Notas</b> (Información complementaria)
        <b-textarea v-model="notas" class="notas"></b-textarea>
        <b-button variant="primary" :disabled="!notas"
          @click="showConfirmation(ticket.tipo.metadatos.final_state)">
          Mandar notas
        </b-button>
      </div>
      <div class="flex-row gap-10 end-center actions">
        <template v-if="!closed">
          <b-button variant="primary"
            @click="showConfirmation(ticket.tipo.metadatos.final_state)"
            :disabled="!validity">Validar</b-button>
        </template>
      </div>
    </div>
    <div v-if="errorState" class="no-printer">
      <div class="flex-row gap-10 end-center actions">
        <b-button variant="secondary"
          @click="showConfirmation(ticket.tipo.metadatos.initial_state)">
            Finalizar correcciones
        </b-button>
      </div>
    </div>

    <!-- Confirmation modal -->
    <b-modal v-model="confirmation" title="Confirmación" @ok="emitChangeState()">
      <div class="text-center">
        ¿Estás seguro que deseas marcar el ticket:
        <div><b>{{ ticket.titulo }} (#{{ ticket.id }})</b></div> como
        <b v-if="newTicketState === ticket.tipo.metadatos.error_state">
          incorrecto
        </b>
        <b v-else-if="newTicketState === ticket.tipo.metadatos.initial_state">
          pendiente
        </b>
        <b v-else>correcto</b>
        ?
      </div>
    </b-modal>

  </div>
</template>

<script>
import TicketPrint from './TicketPrint'
import Attachment from './Attachment'
import { Loader } from '@googlemaps/js-api-loader'
import { mapState, mapActions, mapGetters } from 'vuex'
import { validate } from 'vee-validate';

export default {
  components: { Attachment, TicketPrint },
  data: () => ({
    google: null,
    expanded: false,
    ticket: null,
    ticketView: 'detalle',
    fileUpload: {
      modal: false,
      label: null,
      name: null,
      mime: null,
      value: null,
    },
    validity: false,
    errors: '',
    notas: '',
    confirmation: false,
    newTicketState: null,
    slide: 0,
      movimientos:[],
    prevRoute: null,
      usuarios:[],
      estatus:[],
      ausuario:null,
      movimiento:{
          estatus:null,
          contenido:null,
          solicitud:null,
      },
    loadedMaps: false,
  }),
  beforeRouteEnter(to, from, next) {
    next( vm => vm.prevRoute = from.path )
  },
  async mounted() {
    await this.loadData()
  },
  computed: {
    ...mapState('escuela', ['escuela', 'equipamiento', 'servicios']),
    ...mapGetters(['isStaff']),
    attachments() {
      if (!this.ticket) { return [] }
      return this.ticket.tipo.metadatos.attachments.map( metadata => ({
        attach: this.getAttach(metadata.name),
        metadata,
      }))
    },
    hasAttachments() {
      return this.attachments.filter(a => a.attach).length > 0
    },
    closed() {
      return this.ticket.estatus.id === this.ticket.tipo.metadatos.final_state
    },
    isReader() {
      const state = this.$store.state
      if (!state || !state.info) { return false }

      const readers = this.ticket.tipo.metadatos.readers || []
      for (const w of readers) {
        if (state.info.groups.includes(w)) {
          return true
        }
      }
      return false
    },
    isWatcher() {
      const state = this.$store.state
      if (!state || !state.info) { return false }

      const watchers = this.ticket.tipo.metadatos.watchers || []
      for (const w of watchers) {
        if (state.info.groups.includes(w)) {
          return true
        }
      }
      return false
    },
    errorState() {
      if (!this.ticket) { return false }
      const { error_state } = this.ticket.tipo.metadatos
      return this.ticket.estatus.id === error_state
    },
  },
  methods: {
    ...mapActions('escuela', ['fetchAllEscuelaData']),
    ...mapActions('tickets', [
      'getTicket', 'getTicketMovimientos', 'updateDocument',
      'changeState','assigment','getStaff','getEstatus'
    ]),
    async loadData() {
      this.errors = ''
      this.notas = ''
      return this.getTicket(this.$route.params.id).then( t => {
        this.ticket = t

        if (!this.isStaff && !this.isWatcher && this.ticket.estatus.id !== this.ticket.tipo.metadatos.error_state) {
          alert('No tienes permisos para ver este ticket')
          this.back()
        }

        const loader = new Loader({
          apiKey: process.env.VUE_APP_API_KEY,
          version: process.env.VUE_APP_API_VERSION,
          libraries: ['places']
        })

        loader.load()
          .then( v => this.google = v)
          .then( () => this.$nextTick() )
        this.setValidity()

        this.fetchAllEscuelaData(this.ticket.metadatos.cct)
        this.getTicketMovimientos({ solicitud: this.ticket.id }).then(
          m => this.movimientos = m
        )
          this.getStaff().then(
              m=>this.usuarios = m
          )
          this.getEstatus(this.ticket.tipo.id).then(m=>this.estatus=m)
        this.notas = ''
          this.movimiento.solicitud = this.ticket.id
      })
    },
    back() {
      this.prevRoute === '/' ?
        this.$router.replace({ path: '/tickets' }) :
        this.$router.go(-1)
    },
    getAttach(name) {
      if (!this.ticket || !this.ticket.attachments) {
        return null
      }
      return this.ticket.attachments.find(a => a.nombre === name)
    },
    setValidity() {
      // Fields validity
      const validFields = this.ticket.tipo.metadatos.fields
        .reduce( (v, f) => {
          if (!f.rules || !f.rules.includes('required')) {
            return v
          }
          return v && this.ticket.metadatos[f.name]
        }, true)
      if (!validFields) { return this.validity = false }

      // Attachments validity
      const validAttachs = this.ticket.tipo.metadatos.attachments
        .reduce( (v, a) => {
          if (!a.rules || !a.rules.includes('required')) {
            return v
          }
          return v && !!this.ticket.attachments.find( att => att.nombre === a.name )
        }, true)
      if (!validAttachs) { return this.validity = false }

      // Attachments' Fields validity
      Promise.all(
        this.ticket.attachments
          .filter( a => a.tipo )
          .map( a => {
            return Promise.all(
              a.tipo.criterios.fields.map(f => {
                const value = a.criterios[f.name]
                return validate(value, f.rules).then( r => ({
                  name: f.name,
                  rules: f.rules,
                  valid: r.valid
                }))
              })
            )
          })
      ).then( fieldsValidation => {
        // fieldsValidation.forEach(i => console.table(i))
        return fieldsValidation.reduce(
          (acc, f) => acc && f.reduce(
            (acc, v) => acc && v.valid, true
          ), true
        )
      }).then( v => this.validity = v )
    },
    showConfirmation(state) {
      this.newTicketState = state
      this.confirmation = true
    },
    changeArchivoData(name, data) {
      this.cambiarEstado({
        contenido: `Documento ${name}: ` + Object.entries(data)
          .map(([k, v]) => `${k}=${v}`)
          .join(', '),
        solicitud: this.ticket.id,
        estatus: this.ticket.estatus.id
      }).then( () => this.ticketView = 'documentos' )
    },
    emitChangeState() {
      const { final_state, initial_state } = this.ticket.tipo.metadatos
      this.cambiarEstado({
        contenido:
          this.newTicketState === final_state ? (this.notas || 'Validado') :
          this.newTicketState === initial_state ? 'Correcciones enviadas' :
          this.errors,
        solicitud: this.ticket.id,
        estatus: this.newTicketState
      })
    },
    chooseFile(metadata) {
      this.fileUpload.label = metadata.label
      this.fileUpload.name = metadata.name
      this.fileUpload.mime = metadata.mime
      this.fileUpload.modal = true
    },
    setFile() {
      const file = this.fileUpload.value
      if (!file) { return }
      const formData = new FormData()
      formData.set('file', file)
      this.changeDocument(this.fileUpload.name, formData)
    },
    changeDocument(name, form) {
      this.updateDocument({ tid: this.ticket.id, doc: name, form })
        .then( () => this.loadData() )
    },
    // Store calls
    cambiarEstado(detalleMovimiento) {
      return this.changeState(detalleMovimiento)
        .then( () => this.loadData() )
    },
    sendAssigment(){
        return this.assigment({usuario:this.ausuario,solicitud:this.ticket.id})
    },
    loadMaps() {
      const fields = this.ticket.tipo.metadatos.fields
      const latField = fields.find( f => f.name === 'lat' )
      const lngField = fields.find( f => f.name === 'lng' )

      if (!latField || !lngField) { return }
      const position = {
        lat: Number(this.ticket.metadatos.lat),
        lng: Number(this.ticket.metadatos.lng),
      }
      const ref = this.$refs.map
      const map = new this.google.maps.Map(ref, { zoom: 15, center: position })
      new this.google.maps.Marker({ position, map })
      ref.style.height = '300px'
      this.loadedMaps = true
    }
  },
  watch: {
    ticketView() {
      if (this.ticketView === 'ubicacion' && !this.loadedMaps) {
        this.loadMaps()
      }
    }
  }
}
</script>



<style scoped>
.ticket-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.ticket-content.expanded {
  max-width: 100%;
}
.tabs { margin-bottom: 1rem; border-bottom: 1px solid #CCCC; }
.tabs > * { font-size: small; padding: 4px 8px; }
.tabs > .selected { border-bottom: 2px solid var(--gob-accent); }

/* carousel */
.stroked { text-decoration: line-through; }
.errors { margin-bottom: 0.5rem; }
.notas { margin-bottom: 0.5rem; }
.movimientos { font-size: small; margin-top: 1rem; }
.movimientos .detail { padding: 0.5rem; margin-left: 1rem; }
.ubicacion { margin-top: 1rem; }
.documentos { font-size: small; min-height: 70vh; margin-bottom: 1rem; }
.documentos .slide { min-height: 70vh; }
.no-attachments { font-size: small; }

.state {
  border-radius: 10px;
  display: inline-block;
  background: #444;
  font-size: 0.65rem;
  padding: 0.25rem 0.5rem;
  color: white;
  font-weight: bold;
  vertical-align: top;
  text-transform: uppercase;
}
.state.closed { background: green; }
.state.error { background: #CA0000; }
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/*
.actions { margin: 1rem 0; }

.carousel-item{
    height: 500px;
}
*/
</style>
