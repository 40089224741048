import axios from 'axios'

const get = ({ commit }, catalogo, mutation) => axios
    .get(`/midecc/catalogos/${catalogo}`).then(resp => {
        commit(mutation, resp.data)
        return resp.data
    })

export default {
    namespaced: true,
    state: {
        tiposEscuela: [],
        ambitos: [],
        servicios: [],
        suministros: [],
        estadosServicio: [],
        tiposEquipo: [],
        equipos: [],
    },
    mutations: {
        SET_TIPOS_ESCUELA: (state, v) => state.tiposEscuela = v,
        SET_AMBITOS: (state, v) => state.ambitos = v,
        SET_SERVICIOS: (state, v) => state.servicios = v,
        SET_SUMINISTROS: (state, v) => state.suministros = v,
        SET_ESTADOS_SERVICIO: (state, v) => state.estadosServicio = v,
        SET_TIPOS_EQUIPO: (state, v) => state.tiposEquipo = v,
        SET_EQUIPOS: (state, v) => state.equipos = v,
    },
    actions: {
        getTiposEscuela: ctx => get(ctx, 'tipos-escuela', 'SET_TIPOS_ESCUELA'),
        getAmbitos: ctx => get(ctx, 'ambitos', 'SET_AMBITOS'),
        getServicios: ctx => get(ctx, 'servicios', 'SET_SERVICIOS'),
        getSuministros: ctx => get(ctx, 'suministros', 'SET_SUMINISTROS'),
        getTiposEquipo: ctx => get(ctx, 'tipos-equipo', 'SET_TIPOS_EQUIPO'),
        getEquipos: ctx => get(ctx, 'equipos', 'SET_EQUIPOS'),
        getEstadosServicio: ctx => get(ctx, 'estados-servicio', 'SET_ESTADOS_SERVICIO'),
        send({ rootState }, data) {
            const clave = data.clave || rootState.escuela.escuela.clave
            return axios.post(`/midecc/encuestas/${clave}`, data.request)
        },
        /* eslint-disable-nextline */
        searchCargos: (ctx, search) => axios
          .get('/midecc/catalogos/cargos', { params: { search } })
          .then( resp => resp.data )
    },
    getters: {
        suministrosServicio: state => idServicio => state.suministros.filter(
            s => [idServicio, null].includes(s.tipo)
        ),
        nombreServicio: state => idServicio => {
            const s = state.servicios.find( s => s.id === idServicio ) || {}
            return s.nombre || ''
        },
        nombreEquipo: state => idEquipo => state.equipos.find( e => e.id === idEquipo ).nombre,
        SERVICIO_INEXISTENTE: () => 1,
    }
}
