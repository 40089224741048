<template>
  <div>
    <div class="no-printer">
      <nav class="navbar navbar-gob">
        <a class="navbar-brand" href="/">
            <img src="@/assets/logo-gob-sep.svg" alt="Secretaria de Educación Publica">
        </a>
        <ul class="nav ml-auto">
          <li class="nav-item">
              <a class="nav-link" href="https://www.gob.mx/tramites" target="_blank">Trámites</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" href="https://www.gob.mx/gobierno" target="_blank">Gobierno</a>
          </li>
          <li class="nav-item nav-item-last">
            <a href="https://www.gob.mx/busqueda">
              <b-icon icon="search"></b-icon>
            </a>
          </li>
        </ul>
      </nav>
      <div class="d-flex flex-row menu">
        <router-link v-if="isStaff" to="/mapa">Mapa</router-link>
        <router-link v-if="isStaff"   to="/tickets/instalacion">
          Formulario
        </router-link>
        <!--router-link to="/escuelas">Escuelas</router-link-->
        <div class="flex-grow-1"></div>
        <router-link v-if="isStaff" to="/productividad">Productividad</router-link>
        <router-link v-if="isStaff || isWatcher" to="/tablero">Tablero general</router-link>
        <router-link v-if="access_token" to="/tickets">Admin-Contratos</router-link>
        <router-link v-if="access_token" to="/pendientes">Mis pendientes</router-link>
        <router-link v-if="school"
          :to="'/escuelas/'+school">Mi escuela
        </router-link>
        <span v-if="access_token" @click="salir()">Logout</span>
        <a v-if="!access_token" :href="registerUrl">Registro</a>
        <a v-if="!access_token" @click="goToAuthorize()">Login</a>
      </div>
    </div>
    <div class="printer-only">
      <div class="d-flex justify-content-between container align-items-start mb-2">
        <img src="@/assets/educacion_h.png" width="30%">
        <img src="@/assets/logo-aprendemx.png" width="30%">
      </div>
    </diV>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  mounted() {
    this.getTiposTicket()
  },
  computed: {
    ...mapState(['access_token', 'info']),
    ...mapState('tickets', ['tiposTicket']),
    ...mapGetters(['isStaff']),
    school() {
      return (this.info || {}).escuela
    },
    registerUrl(){
      return process.env.VUE_APP_BACK_END+"registro?redirect="+process.env.VUE_APP_FRONT_END;
    },
    isWatcher() {
      const state = this.$store.state
      if (!state || !state.info) { return false }

      if (!this.tiposTicket) { return false }
      const ID_TIPO_TICKET_INSTALACION = 1
      const tipoTicket = this.tiposTicket.find(
        t => t.id ===ID_TIPO_TICKET_INSTALACION
      )
      if (!tipoTicket) { return false }

      const watchers = tipoTicket.metadatos.watchers || []
      for (const w of watchers) {
        if (state.info.groups.includes(w)) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions(['logout', 'goToAuthorize']),
    ...mapActions('tickets', ['getTiposTicket']),
    salir() {
      this.logout()
      if (!this.$route.path.includes('/mapa')) {
        this.$router.push('/')
      }
    }
  },
}
</script>

<style scoped>
  .no-printer img { width: 338px; }
  ul { font-weight: normal; }
  .navbar.navbar-gob { background-color: var(--gob-primary); padding: .5rem 1rem; }
  .navbar.navbar-gob .nav-item a {
    color: #fff;
    text-transform: inherit;
    font-size: 16px;
  }
  .navbar.navbar-gob .nav-item a:hover, .navbar.navbar-gob .nav-item a:focus {
    color: var(--gob-contrast);
  }
  .nav-item-last a { font-weight: 600; vertical-align: -5px; }
  .ml-auto { margin-left: auto; }

  .menu { background: #222; padding: 5px 10px; }
  .menu a, .menu span {
    color: white !important;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9em;
    padding: 0 5px;
  }
</style>
