<template>
  <div class="footer-gob no-printer">
    <div class="list-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <img src="https://framework-gb.cdn.gob.mx/landing/img/logoheader.svg" href="/" alt="logo gobierno de méxico" class="logo_footer" style="max-width: 90%; margin-left: -27px;   margin-top: 12px;">
          </div>
          <div class="col-sm-3">
            <h2>Enlaces</h2>
            <ul>
              <li><a href="http://www.participa.gob.mx">Participa</a></li>
              <li><a href="https://datos.gob.mx/">Datos</a></li>
              <li><a href="https://www.gob.mx/publicaciones" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Publicaciones Oficiales</a></li>

                      <li><a class="footer" href="http://sep.gob.mx/es/sep1/transparencia_rendicion" id="Transparencia" target="blank" title="Enlace abre en ventana nueva">Portal de Obligaciones de Transparencia</a></li>

                      <li><a class="footer" href="https://www.infomex.org.mx/gobiernofederal/home.action" id="PNT" target="blank" title="Enlace abre PNT en ventana nueva">PNT</a></li>

                      <li><a class="footer" href="http://www.inai.org.mx/" id="INAI" target="blank" title="Enlace abre sitio INAI en ventana nueva">INAI</a></li>

               </ul>
          </div>
          <div class="col-sm-3">
            <h2>¿Qué es gob.mx?</h2>
            <p>Es el portal único de trámites, información y participación ciudadana. <a href="/que-es-gobmx" style="text-decoration: none;">Leer más</a></p>
            <ul>
                    <li><a href="/terminos" title="Versión en inglés del sitio web">English</a></li>

              <li><a href="/terminos">Temas</a></li>

              <li><a href="/terminos">Declaración de Accesibilidad</a></li>
              <li><a href="/terminos">Aviso de privacidad integral</a></li>
              <li><a href="/terminos">Aviso de privacidad simplificado</a></li>
              <li><a href="/terminos">Términos y Condiciones</a></li>
              <li><a href="/terminos#medidas-seguridad-informacion">Política de seguridad</a></li>
              <li><a class="footer" id="Marco Juridico" href="http://www.ordenjuridico.gob.mx" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Marco Jurídico</a></li>
              <li><a href="#">Mapa de sitio</a></li>
            </ul>
          </div>
          <div class="col-sm-3">
              <h2>Contacto</h2>
              <p>Dudas e información a<br>
                <a href="mailto:www.educa.sep.gob.mx/EDUCA/CapPeticionWebCNT"> www.educa.sep.gob.mx</a>
            </p>
            <h2>Síguenos en</h2>
            <ul class="list-inline">
              <li>
                <a id="Facebook" href="https://www.facebook.com/gobmexico" target="_blank" rel="noopener" class="sendEst share-info footer" title="enlace a facebook abre en una nueva ventana">
                  <img alt="Facebook" src="https://framework-gb.cdn.gob.mx/landing/img/facebook.png">
                </a>
              </li>
              <li>
                <a id="Twitter" href="https://twitter.com/GobiernoMX" target="_blank" rel="noopener" class="sendEst share-info footer" title="Enlace a twitter abre en una nueva ventana">
                  <img alt="Twitter" src="https://framework-gb.cdn.gob.mx/landing/img/twitter.png">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid footer-pleca"></div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.footer-gob { background-color: var(--gob-primary-light); }
.footer-gob .list-info {
  padding: 30px 0px 10px;
  margin-bottom: 0px;
}
h2, p { color: white; }
h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}
.footer-gob .list-info ul li {
  list-style-type: none;
  margin: 4px 0px 0px;
  padding: 0px;
  line-height: 110%;
}
.footer-gob .list-info ul {
  margin: 0 0 60px;
  padding: 0;
}
.footer-gob .list-info a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.footer-gob .list-inline li {
  display: inline-block;
}
.footer-pleca {
  background-color: #12322B;
  background-image: url(https://framework-gb.cdn.gob.mx/landing/img/pleca.svg);
  background-repeat: repeat-x;
  height: 48px;
}
</style>
