<template>
  <div class="container">
<h1 class="m-3">Listado de pendientes  de {{info?info.usuario:''}} <b-button v-if="isStaff" variant="success"
                                    @click="agregar = true;  ">
    <b-icon icon="plus"></b-icon>
</b-button> </h1>
      <table class="table table-striped">
          <thead>
          <tr>
              <th scope="col">#</th>
              <th scope="col">CCT</th>
              <th scope="col">Tipo </th>
              <th scope="col">Acción</th>
              <th scope="col">Quitar</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="d in pendientes" v-bind:key="d.id">
              <td>{{d.id}}</td>
              <td>{{d.titulo}}</td>
              <td>{{d.tipo.nombre}}</td>
              <td v-if="d.existe" >
                  <b-button variant="success" size="sm" :to="'/tickets/' + d.solicitud.id">
                      Ver
                  </b-button>
              </td>
              <td v-else>
                  S/R
              </td>
              <td v-if="d.existe" >
                 <div class="rounded-circle m-2" :style="'float:left;width:10px;height:10px;background-color:'+d.solicitud.estatus.color "></div> <p>{{d.solicitud.estatus.nombre}} por {{d.solicitud.modificador}}</p>
              </td>
              <td v-else>
                  Sin reportarse en plataforma
              </td>
              <td>
                  <b-button variant="error" size="sm" @click="quitar(d.id)">
                      quitar
                  </b-button>
              </td>
          </tr>
          </tbody>
      </table>
      <b-modal v-model="agregar" title="Notificar instalación" ok-only>
          <label>CCT</label>
          <b-input v-model="model.cct" plain ref="cct_alta"
          ></b-input>


          <b-button @click="enviar">Guardar</b-button>
      </b-modal>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
name: "Pendiente",
    computed: {
        ...mapGetters(['isStaff']),
        ...mapState(['access_token', 'info']),
        ...mapState(['info']),
    },
    methods: {
        ...mapActions('pendiente', ['fetchData', 'pendiente', 'borrar']),
        enviar() {
            this.model.cct = this.model.cct.trim()
            this.pendiente(this.model).then(() => {
                this.actualiza();
                this.agregar = false;
                this.model.cct = ""
            })
        },
        actualiza() {
            this.fetchData().then(e => this.pendientes = e)
        },
        quitar(id) {
            this.borrar({'id':id}).then(() => this.actualiza())
        }
    },
    data:()=>({
        pendientes:[],
        agregar:false,
        model:{
            tipo:1,
            cct:"",
        }
    }),
    mounted() {
       this.actualiza()
    }
}

</script>