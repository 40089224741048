import Dashboard from '@/modules/escuelas/Dashboard'
import Mapas from '@/components/Mapas'
import MapClasificador from '@/components/mapas/MapClasificador'
import MapServicio from '@/components/mapas/MapServicio'
import MapTickets from '@/components/mapas/MapTickets'
import Tickets from '@/modules/tickets/Tickets'
import TicketContent from '@/modules/tickets/TicketContent'
import TicketInstalacion from '@/modules/tickets/TicketInstalacion'
import Info from '@/modules/usuarios/Info'
import { AuthGuard } from '@/main.store'
import Tablero from "@/modules/tablero/TableroStyled.vue"
import Productividad from "@/modules/tablero/Productividad.vue"
import Inicio from "@/components/Inicio.vue"
import Pendiente from "@/modules/pendientes/Pendiente.vue";
const Consulta = () => import(
  /* webpackChunkName: "consulta" */ '@/components/Consulta.vue')

/**
 * Workaround for usage of multiple route guards
 */
const multi = guards => (to, from, next) => {
  const stack = [].concat(guards)
  const another = () => {
    const guard = stack.pop();
    guard ? guard(to, from, another) : next()
  }
  another()
}
console.log(multi([])(()=>{},()=>{},()=>{}))

const routes = [
  { path: '/info', beforeEnter: AuthGuard, component: Info },
  {
    path: '/encuesta', beforeEnter: AuthGuard,
    component: () => import(
      /* webpackChunkName: 'encuesta' */ ':encuesta/Encuesta'),
  },
  {
    path: '/escuelas/:clave/encuesta', beforeEnter: AuthGuard,
    component: () => import (
      /* webpackChunkName: 'encuesta' */ ':encuesta/Encuesta'),
  },
  {
    path: '/escuelas/:clave', // beforeEnter: AuthGuard,
    component: Dashboard
  },
  { path: '/mapa', component: Mapas },
  { path: '/mapa/clasificacion', component: MapClasificador },
  { path: '/mapa/servicio', component: MapServicio },
  { path: '/mapa/solicitudes', component: MapTickets },
  { path: '/consulta', component: Consulta, beforeEnter: AuthGuard },
  {
    path: '/tickets', beforeEnter: AuthGuard,
    component: Tickets,
  },
  {
    path: '/tickets/instalacion', beforeEnter: AuthGuard,
    component: TicketInstalacion,
  },
  {
    path: '/tickets/:id', beforeEnter: AuthGuard,
    component: TicketContent
  },
  {
    path: '/teleplanteles/renovacion-tecnologica',
    component: MapTickets,
    meta: { hideHeader: true },
  },
  { path: '', component: Inicio },
  // { path: '**', redirectTo: '/login' },
  {
    path: '/tablero', // beforeEnter: AuthGuard,
    component: Tablero
  },
  {
    path: '/productividad', // beforeEnter: AuthGuard,
    component: Productividad
  },
  {
    path: '/pendientes', // beforeEnter: AuthGuard,
    component: Pendiente
  },
];

export default routes;
