<template>
  <div class="d-flex flex-row" style="gap: 1rem;">
    <div class="file">
      <img v-if="isImage" :src="attach.archivo" style="width: 700px">
      <object v-else :data="attach.archivo">
        <embed :src="attach.archivo"/>
      </object>

    </div>

    <div class="file-data">
      <h4>
        {{ num }}. {{ metadata.label }}
        <a :href="attach.archivo" target="_blank"><b-icon icon="link-45deg"></b-icon></a>
      </h4>

      <b-modal v-model="fileUpload.modal" title="Subir archivo"
        :ok-disabled="!fileUpload.value" @ok="setFile()"
        @cancel="fileUpload.value = null">
        <div><b>{{ metadata.label }}</b></div>
        <b-form-file v-model="fileUpload.value" class="form-control"
          ref="finput" placeholder="" plain
          :accept="metadata.mime">
        </b-form-file>
      </b-modal>

      <div>
        <ValidationObserver v-if="isStaff && hasCriterios" v-slot="{ invalid }">
          <div class="d-flex flex-column">
            <div v-for="field of attach.tipo.criterios.fields" :key="field.name" class="field-wrapper">
              <ValidationProvider class="field" :rules="field.rules" v-slot="v">
                <ProxyInput
                  v-model="criterios[field.name]"
                  :label="field.label"
                  :small="true"
                  :type="field.type"
                  :values="field.values"
                  :v="v">
                </ProxyInput>
              </ValidationProvider>
            </div>
          </div>

          <div class="flex-row space-between-start file-send">
            <b-button  variant="gob" size="sm" :disabled="invalid"
              @click="save()">
              Aplicar
            </b-button>
          </div>
        </ValidationObserver>
        <div class="flex-row pt-5">
            <div class="col col-12">
                <div class="d-flex justify-content-start">

                    <b-button v-if="metadata.updatable" size="sm"
                              @click="fileUpload.modal = true"> Reemplazar archivo
                    </b-button>
                </div>
            </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import ProxyInput from '@/modules/forms/ProxyInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { ProxyInput },
  props: {
    num: null,
    attach: null,
    metadata: null,
    reader: null,
  },
  data: () => ({
    criterios: {},
    fileUpload: { modal: false, value: null }
  }),
  created() {
    if (!this.hasCriterios) {
      return
    }
    this.resetCriterios()
  },
  computed: {
    ...mapGetters(['isStaff']),
    hasCriterios() {
      const t = this.attach.tipo
      return t && t.criterios && t.criterios.fields
    },
    isImage() {
      return this.metadata.mime.includes('image')
    },
  },
  methods: {
    ...mapActions('tickets', ['changeAttachmentData']),
    resetCriterios() {
      const criterios = this.attach.criterios || {}
      this.$nextTick( () => {
        this.attach.tipo.criterios.fields.forEach( f => {
          const v = criterios[f.name] || (
            f.type === 'boolean' ? false : null
          )
          this.criterios[f.name] = v
        })
      })
    },
    setFile() {
      const file = this.fileUpload.value
      if (!file) { return }
      const formData = new FormData()
      formData.set('file', file)
      this.$emit('document', formData)
    },
    save() {
      this.changeAttachmentData({
        uuid: this.attach.uuid,
        criterios: { ...this.criterios },
      }).then( () => this.attach.criterios = { ...this.criterios })
        .then( () => this.$emit('change', { ...this.criterios }) )
    }
  }
}
</script>

<style scoped>
.file { flex-grow: 1; }
object, embed { width: 100%; height: 100%; }

.file-data { width: auto; max-width: 400px; }
.field-wrapper { max-width: 300px; }
.field { height:  }
/*
.file-data { --colwidth: 150px; --gapvalue: 7px; --colnum: 4; }

*/
/* The width of all cols plus the gap between the cols */
/*
.file-send { width: calc(var(--colwidth) * var(--colnum) + var(--gapvalue) * 3); margin: 1rem 0; }
*/
</style>
