import axios from 'axios'

export default {
    namespaced: true,
    state: {
        data: null,
    },
    actions:{
        fetchData: (_, params) => axios.get( 'api/v1/cac/alertas/', { params }).then( r => r.data ),
        pendiente:(_,{  cct,tipo })=>axios.post('api/v1/cac/alertas/',{'titulo':cct,'tipo':tipo}).then(r=>r.data),
        borrar:(_,{  id})=>axios.post   (`api/v1/cac/alertas/${id}/delete/`).then(r=>r.data)
    },

}