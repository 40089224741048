<template>
    <div class="container">
        <h1 v-if="escuela">{{ escuela.nombre }}</h1>
        <h5 v-if="escuela">{{ escuela.tipo.nombre }}: {{ escuela.clave }}</h5>
        <router-link v-if="directorOf(clave) || isStaff" to="encuesta" append>
          Actualizar datos
        </router-link>
        <hr class="red"/>

        <div v-if="domicilio">
            <h4>Domicilio</h4>
              <div>
                {{ domicilio.calle }}
                <span v-if="domicilio.numExt">{{ domicilio.numExt }}</span>
                <span v-if="domicilio.numInt">{{ domicilio.numInt }}</span>
              </div>
              <div>
                Asentamiento:
                <span v-if="domicilio.asentamiento">
                  {{ domicilio.asentamiento.nombre }}
                  Cp: {{ domicilio.asentamiento.codPost }}.
                </span>
                <span v-else>{{ domicilio.asentamiento_alt }}.</span>
                <span v-if="domicilio.municipio">{{ domicilio.municipio.nombre }},</span>
                <span v-if="domicilio.estado">{{ domicilio.estado.nombre }}</span>
              </div>
              <div v-if="domicilio.referencia" class="referencia">
                Referencia: {{ domicilio.referencia }}
              </div>
            <div ref="map" class="map"></div>
        </div>
        <div>
            <h4>Plantilla</h4>
            <div class="grid">
                <div></div>
                <div class="black">Hombres</div>
                <div class="black">Mujeres</div>
                <div class="black">Total</div>

                <div class="black">Alumnos</div>
                <div>{{ plantilla.alumnosHombres }}</div>
                <div>{{ plantilla.alumnosMujeres }}</div>
                <div>{{ plantilla.alumnosHombres + plantilla.alumnosMujeres }}</div>

                <div class="black">Docentes</div>
                <div>{{ plantilla.docentesHombres }}</div>
                <div>{{ plantilla.docentesMujeres }}</div>
                <div>{{ plantilla.docentesHombres + plantilla.docentesMujeres }}</div>
            </div>
        </div>
        <div>
            <h4>Servicios</h4>
            <ul>
                <li v-for="s of servicios" :key="s.id">
                    {{ s.nombre }} - Estado: {{ s.estado.nombre }},
                    <div v-if="s.estado.id !== SERVICIO_INEXISTENTE" class="small">
                        Suministro: {{ s.suministro.nombre }},
                        por {{ s.compania }}
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <h4>Equipamiento</h4>
            <div v-for="t of tipoEquipamiento" :key="t.clasificacion" class="tipo-equipo">
                <b>{{ t.clasificacion }}</b>
                <div class="grid header">
                    <div>Equipos</div>
                    <div>Funcionales</div>
                    <div>Descompuestos</div>
                    <div>Funcionales Con Internet</div>
                </div>
                <div v-for="e of t.equipos" :key="e.id" class="grid">
                    <div>{{ e.nombre }}</div>
                    <div>{{ e.funcionales }}</div>
                    <div>{{ e.descompuesto }}</div>
                    <div>{{ e.coninternet }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    clave: null,
  }),
  mounted() {
    this.load()
  },
  computed: {
    ...mapState('escuela', ['escuela', 'domicilio', 'plantilla', 'servicios', 'equipamiento']),
    ...mapGetters(['directorOf', 'isStaff']),
    ...mapGetters('encuesta', ['SERVICIO_INEXISTENTE']),
    tipoEquipamiento() {
      const equipo = {}
      this.equipamiento.forEach( e => {
        if (equipo[e.clasificacion]) {
          equipo[e.clasificacion].equipos.push(e)
        } else {
          equipo[e.clasificacion] = {
            clasificacion: e.clasificacion,
            equipos: [e],
          }
        }
      })
      return equipo
    }
  },
  methods: {
    ...mapActions('escuela', ['fetchAllEscuelaData']),
    load() {
      this.loading = true
      this.clave = this.$route.params.clave
      this.fetchAllEscuelaData(this.clave)
        .then( () => this.initMaps() )
        .finally( () => this.loading = false )
    },
    async initMaps() {
        const loader = new Loader({
          apiKey: process.env.VUE_APP_API_KEY,
          version: process.env.VUE_APP_API_VERSION,
          libraries: ['places']
        })

        this.google = await loader.load()
        const position = {
          lat: this.domicilio.latitud,
          lng: this.domicilio.longitud
        }
        this.map = new this.google.maps.Map(this.$refs.map,
          { zoom: 15, center: position }
        )

        if (this.marker) { this.marker.setMap(null) }
        this.marker = new this.google.maps.Marker({
          position, map: this.map
        })
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // this.load()
      },
    },
  },
}
</script>

<style scoped>
.container { margin-top: 40px; }
.container > div { margin: 20px 0; }
h1, h5 { margin: 0; }
.black { font-weight: bold; }
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
}
.grid.header { font-size: 75%; margin-bottom: 5px; border-bottom: 1px solid black; }
.map { height: 230px; }
.tipo-equipo { margin: 20px 0; }
.referencia { margin-top: 15px; }
</style>
