export default {
  update: function(el) {
    const digits = el.value.replace(/[^0-9]+/g, '');
    if (digits !== el.value) {
      const evt = new Event('input')
      el.value = digits
      el.dispatchEvent(evt)
    }
  }
}
