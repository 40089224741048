<template>
  <div>
    <div class="row">
      <div class="col col-12">
        <div class="p-5 text-center display-6  " v-if="access_token">
          Contesta la Encuesta de Teleplanteles 2024.
          <a href="https://amx.la/Teleplanteles2024">Aquí</a>
        </div>
        <div  v-else class="  p-5">
          <div v-show="!registered">
            <div id="carousel_amx_la_banners-6"></div>
            <p class="testo">
              Docente, para actualizar los datos de tu teleplantel sigue los pasos siguientes: <br/>
              <ol >
                <li>Regístrate en la Nueva Escuela Mexicana Digital haciendo clic <a :href="registerUrl">aquí</a>.</li>
                <li>Activa tu usuario haciendo clic en el enlace que recibiste en el correo registrado
                  en el paso 1.</li>
                <li>Ingresa a atencion.aprende.gob.mx, haz clic en el botón <i>Login</i> y después en <em>Autorizar permisos a la aplicación.</em></li>
                <li> Ingresa el CCT de tu teleplantel y haz clic en <i>Acceder</i>.</li>
                <li>Actualiza los datos de tu teleplantel en la encuesta y haz clic en el botón <i>Enviar.</i></li>
              </ol>
              <ul>
                <li>Si ya estás registrado, inicia sesión aquí: <a class="button_login " v-if="!access_token" @click="goToAuthorize()">Login</a></li>
              </ul>
            </p>
        </div>
          <div v-show="registered">
            <div v-show="!activated">
              Tu cuenta ha sido registrada. No olvides activar tu cuenta con el correo se te ha enviado.
            </div>
            <div v-show="!activated">
              Tu cuenta ha sido activada
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";

export default {
  data: () => ({
    registered:false,
    activated:false
  }),
  computed: {
    ...mapState(['access_token', 'info']),
    ...mapActions([ 'goToAuthorize']),
    registerUrl(){
      return process.env.VUE_APP_BACK_END+"registro?redirect="+process.env.VUE_APP_FRONT_END
    },
  },
  mounted() {

    const {msg} = this.$route.query
    this.registered = msg==='registered'
    this.activated = msg==='registered'
    if (document.amx_load_banners_6 === undefined){

      let script = document.createElement("script")
      script.src = "https://amx.la/code/f459533e-c8ae-4156-89fb-7af6c7c4d3ed/"
      document.body.appendChild(script);
    }

    setTimeout(this.descarga, 1000)
  },
  methods:{
    descarga(){
      document.amx_load_banners_6();
    }
  }
}
</script>
<style scoped>
.testo{
  font-size: 1.2em;
}
.testo ol li{ margin-top: 10px;}
.button_login{
  padding: 6px;
  background-color: #FAB033;
  color: #153D5A;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}
</style>
