<template>
    <div class="col col-12">

        <h3 class="text-center p-3">Desgloses por usuario</h3>
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Fecha</th>
                <th>Modificador</th>
                <th>Solicitudes</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(value, propertyName) in tabla.tickets" >
                <tr v-bind:key="value" ><td colspan="3"><h3 class="text-center">{{propertyName}}</h3></td></tr>
                <tr v-for="v in value" v-bind:key="v.email">
                    <td>{{propertyName}}</td><td>{{v.email}} </td><td> {{v.total}} </td>
                </tr>
            </template>
            </tbody>
        </table>

    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "Productividad",
    data:()=>({
        tabla:[]
    }),
    methods: {
        ...mapActions('tablero', ['usuarios']),
    },
    mounted() {
        this.usuarios().then(e => this.tabla = e)
    }

}
</script>

<style scoped>

</style>