<template>
  <div @keydown="setFecha()">
    <div class="printer-only mb-2" style="text-align: right;">
      <b style="color: grey">Fecha: {{ fecha }}</b>
    </div>
    <h4 class="text-center">
      Verificación de actas de instalación.<br/>
        <b>{{ticket.titulo}}</b>
    </h4>
    <div class="no-printer text-center">
      <b-button class="no-printer" size="sm" @click="print()">Imprimir</b-button>
    </div>

    <div class="mb-3" style="text-align: right;">
      <b style="color: red;">
        {{ ticket.serie ? 'SERIE' : 'ID' }}
        MIDECC: {{ ticket.serie || ticket.id }}
      </b>
    </div>

    <div v-for="(item, i) in attachments" :key="i">
      <b>{{ item.metadata.label }}</b>:
      <div v-if="item.attach" class="py-2 ps-3">
        <div v-for="(field, j) in getFields(item.attach)" :key="'c' + j"
          class="d-flex flex-row justify-content-between" style="gap: 1rem">

          <div style="flex-grow: 1;">{{ field.label }}</div>
          <div style="min-width: 120px;" class="text-center">
            <div v-if="item.attach.criterios[field.name] !== undefined">
              <span v-if="field.type === 'boolean'">
                {{ item.attach.criterios[field.name] ? 'Sí' : 'No' }}
              </span>
              <span v-else>
                {{ item.attach.criterios[field.name] }}
              </span>
            </div>
            <div v-else class="no-answer">Sin respuesta</div>
          </div>

        </div>
      </div>
      <div v-else class="py-2 ps-3">
        N/A
      </div>
    </div>

    <div class="printer-only">
      <div class="d-flex justify-content-center">
        <div class="small text-center"
          style="border-top: 1px solid black; min-width: 30%; margin-top: 4rem">
            {{ticket.modificador}}<br/>
            NOMBRE Y FIRMA DEL ANALISTA VERIFICADOR
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    ticket: null,
  },
  data: () => ({
    fecha: null
  }),
  mounted() {
    this.setFecha()
  },
  computed: {
    // TODO Fix code duplicated in TicketContent.computed.attachments
    attachments() {
      if (!this.ticket) { return [] }
      return this.ticket.tipo.metadatos.attachments.map( metadata => ({
        attach: this.getAttach(metadata.name),
        metadata,
      }))
    }
  },
  methods: {
    // TODO Fix code duplicated in TicketContent.methods.getAttach
    getAttach(name) {
      if (!this.ticket || !this.ticket.attachments) {
        return null
      }
      return this.ticket.attachments.find(a => a.nombre === name)
    },
    getFields(attach) {
      if (!attach || !attach.tipo || !attach.tipo.criterios) {
        return []
      }
      return attach.tipo.criterios.fields || []
    },
    setFecha() {
      this.fecha = new Date().toISOString()
    },
    print() {
      this.setFecha()
      this.$nextTick( () => window.print() )
    }
  },
}
</script>
 
<style scoped>
.no-answer {
  font-style: italic;
}
</style>
