<template>
  <div class="map-wrapper">
    <mapbox-map ref="mapComponent"></mapbox-map>
    <h4 class="title">Centros de Trabajo</h4>
    <div v-if="showBtns" class="d-flex flex-column btns">
      <button v-for="t of tipos" :key="t.siglas"
        :style="{ background: t.color, display: exists(t.siglas) ? 'block' : 'none' }"
        @click="toggleLayers($event.target, t)">
        {{ t.nombre }} ({{ t.siglas }})
      </button>
    </div>
  </div>
</template>

<script>
// import Mapbox from 'mapbox-gl';
import { mapActions, mapState } from 'vuex'
import MapboxMap from './MapboxMap.vue'

export default {
  components: { MapboxMap },
  data: () => ({
    mapComponent: null,
    popup: null,
    showBtns: false,
    cluster: true,
    size: 4,
    layers: [],
    tipos: [],
    tiposObj: {},
  }),
  async mounted() {
    let { estados, tipos, cluster, size } = this.$route.query
    this.size = Number(size) || 2

    // load data
    if (!estados && this.estados.length === 0) { await this.fetchEstados() }
    if (this.tiposEscuela.length === 0) { await this.getTiposEscuela() }

    estados = estados ? estados.split(',') : this.estados.map(e => e.id)
    if (tipos) {
      tipos = tipos.split(',')
      this.tipos = this.tiposEscuela.filter( t => tipos.includes(t.siglas) )

    } else {
      this.tipos = [...this.tiposEscuela]
    }

    this.cluster = cluster === 'true'
    this.tipos.forEach( t => this.tiposObj[t.siglas] = t )
    this.mapComponent = this.$refs.mapComponent
    this.mapComponent.loadMap(() => this.loadData(estados))
  },
  computed: {
    ...mapState('geo', ['estados']),
    ...mapState('encuesta', ['tiposEscuela']),
  },
  methods: {
    ...mapActions('geo', ['fetchEstados']),
    ...mapActions('escuela', ['fetchGeoReferencesIn']),
    ...mapActions('encuesta', ['getTiposEscuela']),
    exists(sigla) {
      return this.layers.find( l => l.includes(sigla) )
    },
    loadData(estados) {
      const tipos = this.tipos.map(t => t.siglas)

      Promise.all(estados.map(
        eid => this.fetchGeoReferencesIn({ eid, tipos }).then(featureCollections => {
          Object.entries(featureCollections).forEach( ([key, featureCollection]) => {

            const prefix = `${key}_${eid}_`
            const { color } = this.tiposObj[key]
            const lighten = this.mapComponent.changeColor(color, 'lighten')
            const darken  = this.mapComponent.changeColor(color, 'darken')

            this.mapComponent.addSource(prefix, {
              type: 'geojson',
              data: featureCollection,
              cluster: this.cluster,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });

            this.mapComponent.addLayer(prefix, prefix + 'unclustered', {
              type: 'circle',
              filter: ['!', ['has', 'point_count']],
              paint: {
                'circle-color': color,
                'circle-radius': this.size,
                'circle-stroke-width': this.cluster ? 1 : 0,
                'circle-stroke-color': '#fff'
              },
            }, {
              click: e => this.onUnClusteredClick(e),
              mouseenter: ({ map }) => map.getCanvas().style.cursor = 'pointer',
              mouseleave: ({ map }) => map.getCanvas().style.cursor = '',
            })
            this.layers.push(prefix + 'unclustered')

            if (!this.cluster) {
              return
            }

            this.mapComponent.addLayer(prefix, prefix + 'clustered', {
              type: 'circle',
              filter: ['has', 'point_count'],
              paint: {
                'circle-color': [
                  'step', ['get', 'point_count'],
                  lighten, 50,
                  color, 200,
                  darken
                ],
                'circle-radius': [
                  'step', ['get', 'point_count'],
                  20, 50,
                  30, 200,
                  40
                ],
              },
            }, { click: e => this.onClusterClick(e), })
            this.layers.push(prefix + 'clustered')

            this.mapComponent.addLayer(prefix, prefix + 'count', {
              type: 'symbol',
              filter: ['has', 'point_count'],
              layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
              },
              paint: { 'text-color': '#fff' }
            })
            this.layers.push(prefix + 'count')
          })
        })
      )).then(
        () => this.mapComponent.on('idle', () => this.showBtns = true )
      );
    },
    onClusterClick({ map, source, feature }) {
      source.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
        if (err) return;
        map.easeTo({ center: feature.geometry.coordinates, zoom });
      });
    },
    onUnClusteredClick({ map, feature, Mapbox, layerId }) {
      const { coordinates } = feature.geometry
      const { CCT, nombre } = { CCT: 'CCT', nombre: '...', ...feature.properties }

      this.popup = new Mapbox.Popup({ layer: layerId })
        .setLngLat(coordinates)
        .setHTML(
          `<b>CCT:</b> ${CCT} <br>`+
          `<b>Nombre:</b> <a href="/escuelas/${CCT}">${nombre}</a>`)
        .addTo(map)
    },
    toggleLayers(el, tipo) {
      const layers = this.layers.filter( l => l.startsWith(tipo.siglas) )
      if (layers.length === 0) {
        return
      }

      const visible = this.mapComponent.getLayervisibility(layers[0]) === 'visible'
      el.style.opacity = visible ? '0.7' : '1.0'

      if (this.popup && this.popup.options.layer.startsWith(tipo.siglas) ) {
        this.popup.remove()
      }

      layers.forEach(
        l => this.mapComponent.setLayerVisibility(l, visible ? 'none' : 'visible')
      )
    }
  }
}
</script>

<style scoped>
.map-wrapper { position: relative; height: 100vh; }
.title {
  position: absolute; top: 0; lefT: 0;
  margin-top: 15px; padding: 10px 20px;
  color: white;
  background: var(--gob-accent);
  font-weight: bold;
}
.btns {
  position: absolute; top: 0; right: 0; margin: 15px;
  resize: vertical;
  max-width: 250px;
  max-height: 300px;
  overflow: auto;
}
button {
  color: white;
  font-weight: bold;
  font-size: 80%;
  padding: 10px;
  border: none;
}

#mapContainer { position: absolute; top: 0; bottom: 0; width: 100%; }
.mapboxgl-canvas-container { height: 100% !important; }
.mapboxgl-canvas-container canvas { height: 100% !important; }
</style>
