import axios from 'axios'
const server = process.env.VUE_APP_BACK_END_GIS

export default {
    namespaced: true,
    state: {
        estados: [],
        municipios: [],
        asentamientos: [],
        estado: null,
        municipio: null,
        asentamiento: null,
    },
    mutations: {
        SET_ESTADOS:    (s, v) => s.estados = v,
        SET_MUNICIPIOS: (s, v) => s.municipios = v,
        SET_ASENTAMIENTOS: (s, v) => s.asentamientos = v,
        SET_ESTADO:    (s, v) => s.estado = v,
        SET_MUNICIPIO: (s, v) => s.municipio = v,
    },
    actions: {
        setEstado:    ({ commit }, v) => commit('SET_ESTADO', v),
        setMunicipio: ({ commit }, v) => commit('SET_MUNICIPIO', v),
        fetchEstados: ({ commit }) => axios.get('/api/general/catalogos/estados/').then( resp => {
            commit('SET_ESTADOS', resp.data)
            return resp.data
        }),
        fetchMunicipios: ({ commit, state }) => axios
            .get(`/api/general/catalogos/estados/${state.estado.id}/municipios`)
            .then( resp => {
                commit('SET_MUNICIPIOS', resp.data)
                return resp.data
            }),
        fetchAsentamientos: ({ commit, state }) => {
            const e = state.estado.id
            const m = state.municipio.id
            return axios.get(
                `/api/general/catalogos/estados/${e}/municipios/${m}/asentamientos`
            ).then( resp => {
                commit('SET_ASENTAMIENTOS', resp.data)
                return resp.data
            })
        },
        centerMunicipio: ({ state }, codigo) => {
          const eid   = (state.estado.id + '').padStart(2,'0')
          return axios.get(`${server}/estados/${eid}/municipios/${codigo}/centroide`)
            .then( resp => resp.data )
        },
        validateCoords: ({ state }, { coords }) => {
          const eid   = (state.estado.id + '').padStart(2,'0')
          const mcode = Number(state.municipio.codigo)
          return axios.post(`${server}/estados/${eid}/municipios/${mcode}/valida-coord/`, coords)
            .then( resp => resp.data.valid )
            .catch( () => false )
        },
        getPerimeter({ state }) {
          const eid   = (state.estado.id + '').padStart(2,'0')
          const mcode = Number(state.municipio.codigo)
          return axios.get(`${server}/estados/${eid}/municipios/${mcode}/perimetro/`)
            .then( resp => resp.data )
        },
    },
    getters: {
      edoNameByClave: state => clave => (
        state.estados.find( e => e.id === Number(clave) ) ||
        { nombre: '' }
      ).nombre
    }
}
