<template>
<div class="wrapper">
  <div class="title">
    <h1 class="primary-text">
      <span class="accent-text">MIDECC</span> -
      Isidoro
    </h1>
  </div>

  <div class="card shadow">
    <div class="grid grey primary-text stats">
      <div
        class="flex-column start-center instalaciones">
          <div>Instalaciones</div>
          <img src="@/assets/tablero-instalaciones.svg"/>
          <b>{{ dglobales.totales | quantity }}</b>
      </div>
      <div v-for="f in dglobales.numeros" v-bind:key="f.id"
        class="flex-column start-center text-center" :class="'id-totales-' + f.id">
          <div>{{ f.nombre }}</div>
          <img :src="getImage(f.id)"/>
          <b>{{ f.solicitudes | quantity }}</b>
          <span class="percentage">
            ({{ (f.solicitudes / dglobales.totales) | percentage }})
          </span>
      </div>
    </div>
    <div class="primary text-center escuelas-wrapper flex-row">
      <div
        class="flex-row escuelas">
          <div class="grow">
            <div>TS</div>
            <b>{{ sumOfEscuelas('telesecundarias') | quantity }}</b>
          </div>
          <div class="grow">
            <div>TB</div>
            <b>{{ sumOfEscuelas('telebachilleratos') | quantity }}</b>
          </div>
      </div>
      <template v-for="f in dglobales.numeros">
        <div :key="f.id + 'd'"
          class="divisor" :class="'id-divisor-' + f.id">
        </div>
        <div :key="f.id" class="flex-row escuelas"
          :class="'id-estado-escuela-' + f.id">
            <div class="grow">
              <div>TS</div>
              <b>{{ escuelasWithStatus(f.id).telesecundarias | quantity }}</b>
            </div>
            <div class="grow">
              <div>TB</div>
              <b>{{ escuelasWithStatus(f.id).telebachilleratos | quantity }}</b>
            </div>
        </div>
      </template>
    </div>
    <div class="grey primary-text text-center decoders-wrapper flex-row">
      <div class="decoders">
        <div>Decodificadores</div>
        <b>
          {{ sumOfDecoders | quantity }}
        </b>
      </div>
      <template v-for="f in dglobales.numeros">
        <div :key="f.id + 'd'"
          class="divisor" :class="'id-divisor-dec-' + f.id">
        </div>
        <div :key="f.id" class="decoders"
          :class="'id-decoder-'+f.id">
          <div>Decodificadores</div>
          <b>{{ decodificadoresWithStatus(f.id).decodificadores | quantity }}</b>
        </div>
      </template>
    </div>
  </div>

  <h4 class="subtitle primary-text">Datos estadísticos</h4>
  <div class="flex-row equipamiento center-stretch primary-text">
    <div class="grow grey card grow text-center shadow">
      <h5>Telesecundarias</h5>
      <b>{{ totales.telesecundarias | quantity }}</b>
      <div class="grid">
        <div>
          <div>Sin energía eléctrica</div>
          <img src="@/assets/tablero-sin-electricidad.svg">
          <b>{{ totales.telesecundarias-totales.telesecundaria_conenergia | quantity }}</b>
        </div>
        <div>
          <div>Sin pantalla</div>
          <img src="@/assets/tablero-sin-pantalla.svg">
          <b>{{ totales.telesecundarias-totales.telesecundaria_conpantalla | quantity }}</b>
        </div>
        <div style="display:none">
          <div>Instalados</div>
          <b>{{ teleplanteles.telesecundarias.instaladas | quantity }}</b>
        </div>
        <div style="display:none">
          <div>Verificados</div>
          <b>{{ teleplanteles.telesecundarias.verificadas | quantity }}</b>
        </div>
      </div>
    </div>
    <div class="grow grey card grow text-center shadow">
      <h5>Telebachilleratos</h5>
      <b>
        {{ (
          totales.telebachilleratos_estatales +
          totales.telebachilleratos_comuntarios
        ) | quantity }}
      </b>
      <div class="grid">
        <div>
          <div>Sin energía eléctrica</div>
          <img src="@/assets/tablero-sin-electricidad.svg">
          <b>
            {{ (
              totales.telebachilleratos_estatales+totales.telebachilleratos_comuntarios -
              totales.tbe_conenergia-totales.tbc_conenergia
            ) | quantity }}
          </b>
        </div>
        <div>
          <div>Sin pantalla</div>
          <img src="@/assets/tablero-sin-pantalla.svg">
          <b>
            {{ (
              totales.telebachilleratos_estatales+totales.telebachilleratos_comuntarios -
              totales.tbe_conpantalla-totales.tbc_conpantalla
            ) | quantity }}
          </b>
        </div>
        <div style="display:none">
          <div>Instalados</div>
          <b>{{ teleplanteles.telebachilleratos.instaladas | quantity }}</b>
        </div>
        <div style="display:none">
          <div>Verificados</div>
          <b>{{ teleplanteles.telebachilleratos.verificadas | quantity }}</b>
        </div>
      </div>
    </div>
  </div>

  <h4 class="subtitle primary-text">Totales</h4>
  <div class="flex-row card grey shadow decoders-totals primary-text">
    <div class="grow">
      Decodificadores instalados
      <b>{{ sumOfDecodersInstalados | quantity }} </b>
      ({{
        sumOfDecodersInstalados /
        totalDecoders
      | percentage }})
    </div>
    <div class="grow">
      Totales
      <b>{{ totalDecoders | quantity }} </b>
    </div>
  </div>

  <div class="progress-wrapper">
    <b-progress  :max="series.instalaciones" show-value>
      <b-progress-bar v-for="p in series.globales" v-bind:key="p.id"  :title="p.nombre"  :value="p.solicitudes" :style="{ 'background-color': p.color }" :label="p.nombre"></b-progress-bar>
    </b-progress>
  </div>

  <h3 class="subtitle primary-text">
    <span class="accent-text">&gt;&gt;</span> Avance estatal
  </h3>
  <div class="grid estados primary-text">
    <template v-for="a in estados">
      <div v-if="a.data"
        class="estado text-center d-flex flex-column" style="gap:1rem;"
        v-bind:key="a.id">
        <div class="nombre shadow">{{ a.nombre }}</div>
        <div class="teleplanteles d-flex flex-row primary shadow">
          <div class="flex-grow-1">
            <div>Total de teleplanteles</div>
            <b class="accent-text">{{
              a.data.total_tbcomunitarios +
              a.data.total_tbestatales +
              a.data.total_telesecundarias
            }}</b>
          </div>
          <div class="divisor"></div>
          <div class="flex-grow-1">
            <div>Instalados</div>
            <b class="accent-text">{{
              a.data.instalados_tbcomunitarios +
              a.data.instalados_tbestatales +
              a.data.instalados_telesecundarias
            }}</b>
            <span class="percentaje accent-text"> ({{
              (
                a.data.instalados_tbcomunitarios +
                a.data.instalados_tbestatales +
                a.data.instalados_telesecundarias
              ) / (
                a.data.total_tbcomunitarios +
                a.data.total_tbestatales +
                a.data.total_telesecundarias
              ) | percentage
            }})</span>
          </div>
        </div>
        <div class="inner-grid total">
        
          <div class="grey shadow-1">
            <div>Telebachilleratos</div>
            <b>{{
              a.data.total_tbcomunitarios +
              a.data.total_tbestatales
            }}</b>
          </div>
          <div class="grey shadow-1">
            <div>Instalados</div>
            <b>{{
              a.data.instalados_tbcomunitarios +
              a.data.instalados_tbestatales
            }}</b>
          </div>
          <div class="grey shadow-1">
            <div>Telesecundarias</div>
            <b>{{ a.data.total_telesecundarias }}</b>
          </div>
          <div class="grey shadow-1">
            <div>instaladas</div>
            <b>{{ a.data.instalados_telesecundarias }}</b>
          </div>

          <div class="flex-grow-1 equip shadow grey">
            <div class="primary">Telesecundarias</div>
            <div class="d-flex flex-row">
              <div class="flex-grow-1">
                <img src="@/assets/tablero-sin-electricidad.svg">
                <div>Sin Energía eléctrica</div>
                <div>
                  <b>{{ (
                    servicios[a.id-1].telesecundarias_totales -
                    servicios[a.id-1].telesecundaria_conenergia
                  ) | quantity }}</b>
                  <span> ({{ (
                    (
                      servicios[a.id-1].telesecundarias_totales -
                      servicios[a.id-1].telesecundaria_conenergia
                    ) / servicios[a.id-1].telesecundarias_totales
                  ) | percentage }})</span>
                </div>
              </div>
              <div class="flex-grow-1">
                <img src="@/assets/tablero-sin-pantalla-alt.svg">
                <div>Sin pantallas</div>
                <div>
                  <b>{{ (
                    servicios[a.id-1].telesecundarias_totales -
                    servicios[a.id-1].telesecundaria_conpantalla
                  ) | quantity }}</b>
                  <span> ({{ (
                    (
                      servicios[a.id-1].telesecundarias_totales -
                      servicios[a.id-1].telesecundaria_conpantalla
                    ) / servicios[a.id-1].telesecundarias_totales
                  ) | percentage }})</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 equip shadow grey">
            <div class="primary">Telebachilleratos</div>
            <div class="d-flex flex-row">
              <div class="flex-grow-1">
                <img src="@/assets/tablero-sin-electricidad.svg">
                <div>Sin Energía eléctrica</div>
                <div>
                  <b>{{ (
                      servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales -
                      servicios[a.id-1].tbc_conenergia+servicios[a.id-1].tbe_conenergia
                  ) | quantity }}</b>
                  <span> ({{ (
                    (
                      servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales -
                      servicios[a.id-1].tbc_conenergia+servicios[a.id-1].tbe_conenergia
                    ) / servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales
                  ) | percentage }})</span>
                </div>
              </div>
              <div class="flex-grow-1">
                <img src="@/assets/tablero-sin-pantalla-alt.svg">
                <div>Sin pantallas</div>
                <div>
                  <b>{{ (
                    servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales -
                    servicios[a.id-1].tbc_conpantalla+servicios[a.id-1].tbe_conpantalla
                  ) | quantity }}</b>
                  <span> ({{ (
                    (
                      servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales -
                      servicios[a.id-1].tbc_conpantalla+servicios[a.id-1].tbe_conpantalla
                    ) / servicios[a.id-1].tbc_totales+servicios[a.id-1].tbe_totales
                  ) | percentage }})</span>
                </div>
              </div>
            </div>
          </div>

          <div class="shadow grey">
            <div>Decodificadores para telesecundarias</div>
            <b>{{ a.data.decodificadores_totales_ts | quantity }}</b>
          </div>
          <div class="shadow grey d-flex flex-column justify-content-center">
            <div>instalados</div>
            <div>
              <b>{{ a.data.decodificadores_instalados_ts }}</b>
              ({{ (
                a.data.decodificadores_instalados_ts /
                a.data.decodificadores_totales_ts
              ) | percentage }})
            </div>
          </div>
          <div class="shadow grey">
            <div>Decodificadores para telebachilleratos</div>
            <b>{{ (
              a.data.decodificadores_totales_tbe +
              a.data.decodificadores_totales_tbc
            ) | quantity }}</b>
          </div>
          <div class="shadow grey d-flex flex-column justify-content-center">
            <div>instalados</div>
            <div>
              <b>{{ (
                a.data.decodificadores_instalados_tbe +
                a.data.decodificadores_instalados_tbc
              ) | quantity }}</b>
              ({{ (
                (
                  a.data.decodificadores_instalados_tbe +
                  a.data.decodificadores_instalados_tbc
                ) / (
                  a.data.decodificadores_totales_tbe +
                  a.data.decodificadores_totales_tbc
                )
              ) | percentage }})
            </div>
          </div>
        </div>

        <div class="text-left">
          <b>
            Instalados:
            {{
              a.data.instalados_tbcomunitarios +
              a.data.instalados_tbestatales +
              a.data.instalados_telesecundarias
            }}
          </b>
          <b-progress class="progress shadow" show-value
            :max="a.data.total_tbcomunitarios+a.data.total_tbestatales+a.data.total_telesecundarias"
          >
            <b-progress-bar title="TB. Comunitarios"
              label="TB. Comunitarios" class="tb progress-bar"
              :value="a.data.instalados_tbcomunitarios">
            </b-progress-bar>
            <b-progress-bar title="TB. Estatales"
              label="TB. Estatales" class="tb progress-bar"
              :value="a.data.instalados_tbestatales">
            </b-progress-bar>
            <b-progress-bar title="Telesecundarias"
              label="Telesecundarias" class="ts progress-bar"
              :value="a.data.instalados_telesecundarias">
            </b-progress-bar>
          </b-progress>
        </div>

        <div class="text-left">
          <b>
            Validados:
            {{
              a.data.verificados_telesecundarias +
              a.data.verificados_tbestatales+a.data.verificados_tbcomunitarios
            }}
          </b>
          <b-progress class="progress shadow" show-value
            :max="a.data.total_tbcomunitarios+a.data.total_tbestatales+a.data.total_telesecundarias"
          >
            <b-progress-bar title="TB. Comunitarios"
              label="TB. Comunitarios" class="tb progress-bar"
              :value="a.data.verificados_telesecundarias">
            </b-progress-bar>
            <b-progress-bar title="TB. Estatales"
              label="TB. Estatales" class="tb progress-bar"
              :value="a.data.verificados_tbestatales">
            </b-progress-bar>
            <b-progress-bar title="Telesecundarias"
              label="Telesecundarias" class="ts progress-bar"
              :value="a.data.verificados_tbcomunitarios">
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
    </template>
  </div>

  <h4 class="subtitle primary-text">Flujo de entrega de reportes</h4>
  <Bar
    :chart-options="chartOptions"
    :chart-data="series.chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="600"
    :height="450"
  />

  <div v-if="regresosPendiente" class="mt-4">
    <b>
      Regresos a Pendiente: {{ regresosPendiente }}
    </b>
  </div>
</div>
</template>

<script>
import { mapActions, mapState  } from 'vuex'
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// Percentaje round
const PERCENTAJE_DECIMALS = 2
const MULTIPLY_FACTOR = Math.pow(10, 2 + PERCENTAJE_DECIMALS)
const DIVISION_FACTOR = Math.pow(10, PERCENTAJE_DECIMALS)

export default {
  name: "Tablero",
  components: { Bar },
  filters: {
    percentage(value) {
      const val= Math.round(value * MULTIPLY_FACTOR) / DIVISION_FACTOR
      return ( isNaN(val) ? '0%' : val + '%')
    },
    quantity(value) {
      const pattern = '\\B(?=(\\d{3})+(?!\\d))'
      const regex = new RegExp(pattern, 'g')
      if (!value) {
        value = 0
      }
      return (value.toString()).replace(regex, ',')
    }
  },
  computed: {
    ...mapState('tablero', ['data']),
    sumOfDecodersInstalados() {
      return this.decodificadorStats.filter(s => s.id !== 4).reduce(
        (acc, v) => acc + v.decodificadores, 0
      )
    },
    sumOfDecoders() {
      return this.decodificadorStats.reduce(
        (acc, v) => acc + v.decodificadores, 0
      )
    }
  },
  methods: {
    ...mapActions('tablero', [
      'fetchData',
      'usuarios',
      'globales',
      'lestados',
      'eestados',
      'getInstalacionEscuelaStats',
      'getDecodificadorStats',
      'getTotalDecodificadores',
      'getTotalRegresosPendiente'
    ]),
    getImage(id) {
      const image1 = require('@/assets/tablero-pendiente.svg')
      const image2 = require('@/assets/tablero-validado.svg')
      const image3 = require('@/assets/tablero-errores.svg')
      const image4 = require('@/assets/tablero-sin-documentacion.svg')
      console.log(image1)
      return {
        1: image1,
        2: image2,
        3: image3,
        4: image4,
      }[id] || ''
    },
    escuelasWithStatus(eid) {
      const items = this.instalacionEscuelaStats
      return items.find( i => i.id === eid ) || {}
    },
    sumOfEscuelas(tipo) {
      return this.instalacionEscuelaStats.reduce(
        (acc, v) => acc + v[tipo], 0
      )
    },
    decodificadoresWithStatus(eid) {
      const items = this.decodificadorStats
      return items.find( i => i.id === eid ) || {}
    }
  },
  data: () => ({
    chartId:'bar-lab',
    datasetIdKey:'label',
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    },
    tabla: {},
    dglobales: [],
    estados: [],
    teleplanteles:{
      decodificadores: 0,
      decodificadores_instalados: 0,
      telesecundarias: {
        instaladas: 0,
        verificadas: 0,
        error: 0
      },
      telebachilleratos: {
        instaladas: 0,
        verificadas: 0,
        error: 0
      },
    },
    series: {
      retrasos:0,
      aglobal:0,
      incremento:0,
      resueltos:0,
      instalaciones:0,
      incidencias:0,
      usuarios:0,
      programadas:0,
      averificadas:0,
      chartData: {
        labels: [
          '05 de junio',
          '06 de junio',
          '07 de junio',
          '08 de junio',
          '09 de junio',
          '10 de junio',
          '11 de junio',
          '12 de junio'
        ],
        datasets: [
          {
            label: 'Instalaciones',
            backgroundColor: '#f87979',
            data: [0, 0, 0, 0, 0, 0, 0,0]
          }
        ]
      },
      estados: [],
      globales: [
        {'solicitudes':0},
        {'solicitudes':0},
        {'solicitudes':0}
      ]
    },
    servicios: [
      {
        'telesecundaria_conenergia': 157,
        'telesecundarias_totales': 157,
        'tbe_totales': 17,
        'tbc_totales': 85,
        'telesecundaria_conpantalla': 109,
        'tbc_conenergia': 85,
        'tbc_conpantalla': 60,
        'tbe_conenergia': 1,
        'tbe_conpantalla': 1
      },
      {
        'telesecundaria_conenergia': 96,
        'telesecundarias_totales': 103,
        'tbe_totales': 0,
        'tbc_totales': 16,
        'telesecundaria_conpantalla': 73,
        'tbc_conenergia': 16,
        'tbc_conpantalla': 9,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 64,
        'telesecundarias_totales': 64,
        'tbe_totales': 0,
        'tbc_totales': 55,
        'telesecundaria_conpantalla': 51,
        'tbc_conenergia': 54,
        'tbc_conpantalla': 20,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 156,
        'telesecundarias_totales': 157,
        'tbe_totales': 0,
        'tbc_totales': 19,
        'telesecundaria_conpantalla': 85,
        'tbc_conenergia': 19,
        'tbc_conpantalla': 12,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 1437,
        'telesecundarias_totales': 1484,
        'tbe_totales': 117,
        'tbc_totales': 231,
        'telesecundaria_conpantalla': 1059,
        'tbc_conenergia': 217,
        'tbc_conpantalla': 52,
        'tbe_conenergia': 117,
        'tbe_conpantalla': 109
      },
      {
        'telesecundaria_conenergia': 351,
        'telesecundarias_totales': 363,
        'tbe_totales': 82,
        'tbc_totales': 129,
        'telesecundaria_conpantalla': 216,
        'tbc_conenergia': 117,
        'tbc_conpantalla': 89,
        'tbe_conenergia': 79,
        'tbe_conpantalla': 52
      },
      {
        'telesecundaria_conenergia': 46,
        'telesecundarias_totales': 46,
        'tbe_totales': 0,
        'tbc_totales': 0,
        'telesecundaria_conpantalla': 38,
        'tbc_conenergia': 0,
        'tbc_conpantalla': 0,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 102,
        'telesecundarias_totales': 104,
        'tbe_totales': 0,
        'tbc_totales': 186,
        'telesecundaria_conpantalla': 82,
        'tbc_conenergia': 152,
        'tbc_conpantalla': 110,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 59,
        'telesecundarias_totales': 59,
        'tbe_totales': 0,
        'tbc_totales': 25,
        'telesecundaria_conpantalla': 43,
        'tbc_conenergia': 23,
        'tbc_conpantalla': 11,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 545,
        'telesecundarias_totales': 571,
        'tbe_totales': 0,
        'tbc_totales': 100,
        'telesecundaria_conpantalla': 439,
        'tbc_conenergia': 99,
        'tbc_conpantalla': 96,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 1104,
        'telesecundarias_totales': 1123,
        'tbe_totales': 0,
        'tbc_totales': 354,
        'telesecundaria_conpantalla': 971,
        'tbc_conenergia': 352,
        'tbc_conpantalla': 261,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 981,
        'telesecundarias_totales': 1049,
        'tbe_totales': 0,
        'tbc_totales': 316,
        'telesecundaria_conpantalla': 673,
        'tbc_conenergia': 282,
        'tbc_conpantalla': 89,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 761,
        'telesecundarias_totales': 769,
        'tbe_totales': 0,
        'tbc_totales': 135,
        'telesecundaria_conpantalla': 682,
        'tbc_conenergia': 125,
        'tbc_conpantalla': 83,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 638,
        'telesecundarias_totales': 642,
        'tbe_totales': 0,
        'tbc_totales': 60,
        'telesecundaria_conpantalla': 329,
        'tbc_conenergia': 59,
        'tbc_conpantalla': 22,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 994,
        'telesecundarias_totales': 1044,
        'tbe_totales': 0,
        'tbc_totales': 520,
        'telesecundaria_conpantalla': 825,
        'tbc_conenergia': 502,
        'tbc_conpantalla': 73,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 918,
        'telesecundarias_totales': 930,
        'tbe_totales': 182,
        'tbc_totales': 42,
        'telesecundaria_conpantalla': 270,
        'tbc_conenergia': 36,
        'tbc_conpantalla': 33,
        'tbe_conenergia': 68,
        'tbe_conpantalla': 33
      },
      {
        'telesecundaria_conenergia': 149,
        'telesecundarias_totales': 151,
        'tbe_totales': 0,
        'tbc_totales': 8,
        'telesecundaria_conpantalla': 102,
        'tbc_conenergia': 7,
        'tbc_conpantalla': 2,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 289,
        'telesecundarias_totales': 296,
        'tbe_totales': 0,
        'tbc_totales': 56,
        'telesecundaria_conpantalla': 208,
        'tbc_conenergia': 15,
        'tbc_conpantalla': 12,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 92,
        'telesecundarias_totales': 97,
        'tbe_totales': 0,
        'tbc_totales': 76,
        'telesecundaria_conpantalla': 73,
        'tbc_conenergia': 74,
        'tbc_conpantalla': 19,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 1563,
        'telesecundarias_totales': 1604,
        'tbe_totales': 260,
        'tbc_totales': 99,
        'telesecundaria_conpantalla': 737,
        'tbc_conenergia': 67,
        'tbc_conpantalla': 57,
        'tbe_conenergia': 257,
        'tbe_conpantalla': 344
      },
      {
        'telesecundaria_conenergia': 1370,
        'telesecundarias_totales': 1429,
        'tbe_totales': 0,
        'tbc_totales': 95,
        'telesecundaria_conpantalla': 1030,
        'tbc_conenergia': 80,
        'tbc_conpantalla': 12,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 237,
        'telesecundarias_totales': 241,
        'tbe_totales': 0,
        'tbc_totales': 29,
        'telesecundaria_conpantalla': 176,
        'tbc_conenergia': 26,
        'tbc_conpantalla': 16,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 172,
        'telesecundarias_totales': 179,
        'tbe_totales': 0,
        'tbc_totales': 79,
        'telesecundaria_conpantalla': 70,
        'tbc_conenergia': 79,
        'tbc_conpantalla': 35,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 1105,
        'telesecundarias_totales': 1127,
        'tbe_totales': 0,
        'tbc_totales': 38,
        'telesecundaria_conpantalla': 659,
        'tbc_conenergia': 30,
        'tbc_conpantalla': 24,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 330,
        'telesecundarias_totales': 336,
        'tbe_totales': 0,
        'tbc_totales': 100,
        'telesecundaria_conpantalla': 245,
        'tbc_conenergia': 92,
        'tbc_conpantalla': 55,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 279,
        'telesecundarias_totales': 283,
        'tbe_totales': 0,
        'tbc_totales': 34,
        'telesecundaria_conpantalla': 239,
        'tbc_conenergia': 33,
        'tbc_conpantalla': 24,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 446,
        'telesecundarias_totales': 458,
        'tbe_totales': 8,
        'tbc_totales': 50,
        'telesecundaria_conpantalla': 227,
        'tbc_conenergia': 50,
        'tbc_conpantalla': 21,
        'tbe_conenergia': 7,
        'tbe_conpantalla': 25
      },
      {
        'telesecundaria_conenergia': 298,
        'telesecundarias_totales': 300,
        'tbe_totales': 6,
        'tbc_totales': 43,
        'telesecundaria_conpantalla': 197,
        'tbc_conenergia': 0,
        'tbc_conpantalla': 0,
        'tbe_conenergia': 6,
        'tbe_conpantalla': 1
      },
      {
        'telesecundaria_conenergia': 146,
        'telesecundarias_totales': 146,
        'tbe_totales': 0,
        'tbc_totales': 5,
        'telesecundaria_conpantalla': 97,
        'tbc_conenergia': 5,
        'tbc_conpantalla': 3,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 2384,
        'telesecundarias_totales': 2460,
        'tbe_totales': 1077,
        'tbc_totales': 30,
        'telesecundaria_conpantalla': 1628,
        'tbc_conenergia': 28,
        'tbc_conpantalla': 15,
        'tbe_conenergia': 1025,
        'tbe_conpantalla': 2799
      },
      {
         'telesecundaria_conenergia': 187,
         'telesecundarias_totales': 189,
         'tbe_totales': 0,
         'tbc_totales': 198,
         'telesecundaria_conpantalla': 120,
         'tbc_conenergia': 196,
         'tbc_conpantalla': 150,
         'tbe_conenergia': 0,
         'tbe_conpantalla': 0
      },
      {
        'telesecundaria_conenergia': 877,
        'telesecundarias_totales': 894,
        'tbe_totales': 0,
        'tbc_totales': 77,
        'telesecundaria_conpantalla': 538,
        'tbc_conenergia': 69,
        'tbc_conpantalla': 40,
        'tbe_conenergia': 0,
        'tbe_conpantalla': 0
      }
    ],
    totales: {
      "tbe_aulas":7165,
      "tbc_aulas":9350,
      "telesecundaria_aulas":75045,
      "telesecundarias":18855,
      "telebachilleratos_comuntarios":3290,
      "telebachilleratos_estatales":1749,
      "telesecundaria_conenergia":	18333	,
      "telesecundaria_conpantalla":	12291	,
      "tbc_conenergia":	2989	,
      "tbc_conpantalla":	1505	,
      "tbe_conenergia":	1560	,
      "tbe_conpantalla":	821
    },
    max: 100,
    instalacionEscuelaStats: [],
    decodificadorStats: [],
    totalDecoders: 0,
    regresosPendiente: null
  }),
  mounted() {
    this.fetchData().then(e=>this.series =e)
    this.globales().then(e=>this.dglobales =e)

    this.getTotalDecodificadores()
      .then( v => this.totalDecoders = v )
    this.getInstalacionEscuelaStats()
      .then(v => this.instalacionEscuelaStats = v)
    this.getDecodificadorStats()
      .then(v => this.decodificadorStats = v)
    this.getTotalRegresosPendiente()
      .then(v => this.regresosPendiente = v)

    this.lestados().then(e=>{
      this.estados =e
      this.estados.forEach(s=>{
        this.eestados({id:s.id}).then(r=>{
          s.data=r
          this.teleplanteles.decodificadores += s.data.decodificadores_totales
          this.teleplanteles.decodificadores_instalados += s.data.decodificadores_instalados
          this.teleplanteles.telesecundarias.instaladas += s.data.instalados_telesecundarias
          this.teleplanteles.telesecundarias.verificadas += s.data.verificados_telesecundarias
          this.teleplanteles.telebachilleratos.verificadas += s.data.verificados_tbestatales+s.data.verificados_tbcomunitarios
          this.teleplanteles.telebachilleratos.instaladas += s.data.instalados_tbestatales+s.data.instalados_tbcomunitarios
        })
      })
    })
  }
}
</script>

<style scoped>
.wrapper {
  padding: 40px;
  max-width: 1600px;
  margin: 0 auto;
  --red: #D44;
}
.title h1 { font-weight: bold; }
.card { margin-bottom: 2rem; }
.subtitle { font-weight: bold; }

/* stats */
.stats.grid {
  display: grid;
  grid-template-columns: repeat(5, 16%);
  gap: 20px;
  padding: 20px;
}
.stats .instalaciones { border-right: 1px solid var(--primary); }
.stats .id-totales-4 { order: 1; }

/* escuelas-wrapper */
.escuelas-wrapper {
  padding: 20px;
  gap: 10px;
}
.escuelas-wrapper .escuelas { width: 16%; }
.escuelas-wrapper .divisor { border-left: 1px solid var(--accent); width: 0; }
.escuelas-wrapper .id-divisor-4 { order: 1 }
.escuelas-wrapper .id-estado-escuela-4 { order: 2; }

/* decoders */
.decoders-wrapper {
  padding: 20px;
  gap: 10px;
}
.decoders-wrapper .decoders { width: 16%; }
.decoders-wrapper .divisor { border-left: 1px solid var(--primary); width: 0; }
.decoders-wrapper .id-divisor-dec-4 { order: 1 }
.decoders-wrapper .id-decoder-4 { order: 2; }

/* equipamiento */
.equipamiento { gap: 20px; }
.equipamiento .card { padding: 1.5rem; }
.equipamiento .grid {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px 0;
}
.equipamiento img { display: block; margin: 0 auto; }

/* decoders-totals */
.decoders-totals { padding: 30px; }
.decoders-totals b { margin-left: 1rem; } 

/* generals */
img { width: 5rem; }
.capitalize { text-transform: capitalize; }
.grey { background: #EEE; }
.percentage { font-size: 80%; }
.progress-wrapper { margin: 4rem 0; }
.shadow-alt { box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%); }

/* estados */
.estados.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
}
.estado { padding: 1rem; margin-bottom: 1rem; }
.estado .nombre {
  background-color: var(--red);
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
.estados .teleplanteles {
  font-weight: bold;
  width: 100%;
  padding: 0.75rem 1rem;
}
.estados .teleplanteles .flex-grow-1 { max-width: 50%; }
.estados .divisor {
  border-left: 1px solid var(--accent); width: 0;
  height: 90%;
  margin: auto auto;
}
.shadow-1 { box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important }
.estados .inner-grid {
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  gap: 1rem;
}
.estados .inner-grid > div {
  border: 1px solid rgba(50, 50, 50, 0.125);
}
.estados .equip { padding-bottom: 1rem; }
.estados .equip .primary {
  font-weight: bold;
  padding: 0.5rem 1rem;
}
.estados .equip img { width: 3.5rem; margin-top: 0.5rem; }
.estados .equip .flex-grow-1 { max-width: 50%; }

.estados .progress {
  height: 2rem;
  font-weight: bold;
  border: 1px solid rgba(50, 50, 50, 0.125);
}
.estados .progress-bar.tb { background: var(--red); }
.estados .progress-bar.ts { background: var(--primary); }

.text-left { text-align: left; }
</style>
