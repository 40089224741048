<template>
  <ValidationObserver v-slot="{ invalid, valid }">
  <div class="container" @keyup.enter="valid && submit()">
    <h4>
      Datos de Director
      <b-icon v-if="!edit" class="edit"
        @click="edit = true" icon="pencil-fill">
      </b-icon>
    </h4>
    <hr class="gob"/>

    <div class="flex-row gap-10">
      <ValidationProvider class="field grow" rules="required|email" v-slot="v">
        <b-form-group label="Correo de Usuario*">
          <b-input v-model="infoForm.usuario" disabled :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field grow" rules="required" v-slot="v">
        <b-form-group label="Nombre(s)*">
          <b-input v-model="infoForm.nombre" v-uppercase maxLength="100"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>
    </div>

    <div class="flex-row gap-10">
      <ValidationProvider class="field grow" rules="required" v-slot="v">
        <b-form-group label="Primer Apellido*">
          <b-input v-model="infoForm.apellido1" v-uppercase maxLength="50"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field grow" rules="required" v-slot="v">
        <b-form-group label="Segundo Apellido*">
          <b-input v-model="infoForm.apellido2" v-uppercase maxLength="50"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>
    </div>

    <div class="flex-row gap-10">
      <ValidationProvider class="field" rules="required|min:10" v-slot="v">
        <b-form-group label="Teléfono Movil*">
          <b-input v-model="infoForm.celular" v-digits maxLength="10"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" rules="required|min:10" v-slot="v">
        <b-form-group label="Telefono Trabajo*">
          <b-input v-model="infoForm.telefono" v-digits maxLength="10"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" rules="" v-slot="v">
        <b-form-group label="Ext.">
          <b-input v-model="infoForm.ext" v-digits maxLength="5"
            :disabled="!edit" :state="$state(v)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>
    </div>

    <template v-if="create">
      <p class="small">
        <br>
        Busca la <b>CCT</b> de tu escuela para llenar los datos con la información
        previamente recopilada. <br>
      </p>

      <div class="flex-row start-center gap-10">
        <ValidationProvider class="field" rules="required|min:10" v-slot="v">
          <b-form-group label="CCT*">
            <b-input v-model="cct"
              :state="$state(v)"
              v-uppercase maxLength="10"
              list="cct-list"
              @input="loadEscuelas($event)">
            </b-input>
            <datalist id="cct-list">
              <option v-for="e in escuelas" :key="e.clave">{{ e.clave }}</option>
            </datalist>
            <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
          </b-form-group>
        </ValidationProvider>

        <b-alert class="small"
          :show="cct.length === 10"
          :variant="escuela ? 'info' : 'warning'">
            <template v-if="escuela">
              La <b>CCT</b> ha sido <b>encontrada</b> cargaremos la
              información previamente obtenida.
            </template>
            <template v-else>
              No se ha encontrado información previa de la CCT,
              favor de <b>continuar con el registro.</b>
            </template>
        </b-alert>
      </div>
    </template>

    <div class="flex-row gap-10 end-start" v-if="edit">
      <b-button v-if="!create" variant="gob" @click="edit = false">Cancelar</b-button>
      <b-button variant="gob" @click="submit()" :disabled="invalid">Aceptar</b-button>
    </div>
  </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    edit: false,
    create: false,
    estado: undefined,
    infoForm: {},
    cct: '',
    escuelas: [],
    escuela: null,
  }),
  created() {
    const { create } = this.$route.query
    const { SELF_REGISTRY_DIRECTORS } = this.constants
    if (this.info.perfil === null && create && SELF_REGISTRY_DIRECTORS) {
      this.edit = true
      this.create = true
    }
    this.loadForm()
    this.fetchEstados()
  },
  computed: {
    ...mapState(['info', 'constants']),
    ...mapState('geo', ['estados']),
  },
  methods: {
    ...mapActions(['createUserInfo', 'fetchUserInfo']),
    ...mapActions('escuela', ['fetchEscuelas', 'fetchAllEscuelaData']),
    ...mapActions('geo', ['setEstado', 'fetchEstados', 'fetchMunicipios']),
    loadForm() {
      this.infoForm = { ...this.info }
      this.infoForm.perfil = undefined
      this.infoForm.escuela = undefined
    },
    loadEscuelas(ev) {
      if ((ev.ctrlKey || ev.metaKey) && ev.keyCode === 86) {
        ev.preventDefault()
        return
      }

      if (this.cct.length == 0) {
        this.escuela = null
        return
      }
      this.fetchEscuelas({ search: this.cct, director: 'null' })
        .then( v => this.escuelas = v )
        .then( () => {
          if (this.cct.length === 10) {
            this.escuela = this.escuelas.find( e => e.clave === this.cct )
          }
        })
    },
    submit() {
      const data = {
        ...this.infoForm,
        ext: this.infoForm.ext || null,
        escuela: this.escuela ? this.escuela.clave : null,
      }

      const promise = this.create ?
        this.createUserInfo(data) :
        this.updateUserInfo(data)

      promise.then( () => {
        this.edit = false
        return this.fetchUserInfo()
      }).then( async () => {
        if (this.create) {
          this.create = false

          if (data.escuela) {
            await this.fetchAllEscuelaData(data.escuela)
          }

          this.$router.push({ path: '/encuesta' })
        }
      })
    },
    cancel() {
      this.loadForm()
      this.edit = false
    }
  }
}
</script>

<style scoped>
hr.gob { margin-bottom: 30px; }
.edit { cursor: pointer; font-size: 15px; vertical-align: baseline; }
.container { padding-top: 30px; padding-bottom: 30px; }
</style>
