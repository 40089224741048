<script src="../../directives/Lowercase.js"></script>
<template>
  <div class="container">
    Tipo Solicitud:
    <b-select v-model="tipoTicket" plain class="mb-2"
      :disabled="loading">
      <b-option v-for="t of tiposTicket" :key="'t' + t.id" :value="t">
        {{ t.nombre }}
      </b-option>
    </b-select>

    <div v-if="tipoTicket">
      Estado del ticket:
      <b-select v-model="estatusTicket" plain class="mb-2"
        :disabled="loading">
        <template v-if="isStaff || isWatcher">
          <b-option :value="tipoTicket.metadatos.initial_state">
            Pendiente
          </b-option>
          <b-option :value="tipoTicket.metadatos.schedule">
            Programado
          </b-option>
        </template>
        <b-option :value="tipoTicket.metadatos.error_state">
          Con errores
        </b-option>
        <b-option v-if="isStaff" :value="tipoTicket.metadatos.final_state">
          Validado
        </b-option>
        <b-option v-if="isStaff" value="5">
          Finalizado
        </b-option>

      </b-select>
    </div>
    <div v-if="estatusTicket">
      <div class="d-flex align-items-end mb-4" style="gap: 1rem">
        <div class="flex-grow-1">
          Búsqueda:
          <b-input v-model="searchField" plain ref="search"
            :disabled="loading"></b-input>
        </div>
          <b-button variant="primary" :disabled="loading"
                    @click="search = searchField">
              <b-icon icon="search"></b-icon>
          </b-button>
          <b-button v-if="isStaff" variant="primary"
                    @click="agendar = true;  ">
              <b-icon icon="calendar"></b-icon>
          </b-button>

      </div>

      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row size align-items-center gap-10">
          <div class="text">Elementos por página:</div>
          <b-select v-model="size" plain class="select"
            :disabled="loading">
            <b-option :value="10">10</b-option>
            <b-option :value="20">20</b-option>
            <b-option :value="50">50</b-option>
          </b-select>
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center">
            <b-button variant="gob"
                      :disabled="loading||!items.previous"
                      @click="page = 1">Primera
            </b-button>
            <b-button variant="gob"
            :disabled="loading||!items.previous"
            @click="page = page - 1">Anterior
          </b-button>


          <b-button variant="text" style="width: max-content"> Página: {{ page }}</b-button>
          <b-button variant="gob"
            :disabled="loading||!items.next"
            @click="page = page + 1">Siguiente
          </b-button>
          <b-button variant="gob"
            :disabled="loading"
            @click="page = pages">Última
          </b-button>
        </div>
      </diV>

      <div v-if="items.count > 0" style="font-size: small">
        Página {{ page }} de {{ pages }}. Hay <b>{{ totales }}</b> registros
      </div>

      <b-table striped hover style="margin-top: 1rem;"
        :items="items.results" :fields="fields">
        <template #cell(id)="data">
          {{ data.item.serie }}
        </template>
        <template #cell(b12k)="data">
          {{ data.item.metadatos.b12k?'Si':'No' }}
        </template>
        <template #cell(estado)="data">
          <span v-if="data.item.metadatos.cct">
            {{ edoNameByClave(data.item.metadatos.cct.substring(0, 2)) }}
          </span>
        </template>
          <template #cell(creado)="data">
              {{ data.item.creado | date }}
          </template>
          <template #cell(actualizado)="data">
              {{ data.item.actualizado | date }}
          </template>
          <template #cell(modificador)="data">
              {{ data.item.modificador  }}
          </template>
          <template #cell(correo)="data">
              <div v-if="data.item.attachments">
                  {{ getCriterios(data.item).fechaCorreo | date }}
              </div>
          </template>
          <template #cell(instalacion)="data">
              <div v-if="data.item.attachments">
              {{ getCriterios(data.item).fecha | date }}
              </div>
          </template>
        <template #cell(aulas)="data">
          <div v-if="data.item.attachments">
            {{ getCriterios(data.item).aulas  }}
          </div>
        </template>
        <template #cell(sello)="data">
          <div v-if="data.item.attachments">
            {{ getCriterios(data.item,2).rubricas?'No':'Si'  }}
          </div>
        </template>
        <template #cell(barra)="data">
          <div v-if="data.item.attachments">
            {{ getCriterios(data.item,4).canal?'Si':'No'  }}
          </div>
        </template>
        <template #cell(titulo)="data">
          {{ data.item.titulo }}
        </template>
        <template #cell(archivos)="data">
          {{ data.item.attachments | count }}
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" :to="'/tickets/' + data.item.id">
            {{ data.field.label }}
          </b-button>
        </template>
      </b-table>
      <b-button v-if="isStaff && this.estatusTicket " @click="genera_descarga">
        Genera CSV
      </b-button>
      <vue-json-to-csv v-if="descargar" :json-data="descarga">
        <b-button variant="success">
          <b>Descargar</b>
        </b-button>
      </vue-json-to-csv>
      <div class="spinner-border text-secondary" v-else-if="loading">
        <span class="sr-only"><span class=""></span>..</span>

      </div>
      <div v-if="items.count === 0" class="no-items">
        No se encontraron elementos
      </div>
    </div>
      <b-modal v-model="agendar" title="Agendar escuela"  @shown="focusCCT" >
          <b-form-group>
          <label>CCT</label>
          <b-input v-model="model.cct" plain ref="cct_alta"
          ></b-input>
          <label>Fecha de correo</label>

          <b-input v-model="model.correo" type="date"
          ></b-input>
          <label>Fecha de instalación</label>
          <b-input v-model="model.instalacion" type="date"
          ></b-input>
          <label>Numero  de aulas</label>
          <b-input v-model="model.aulas" type="number"
          ></b-input>
          </b-form-group>
          <template #modal-footer> <b-button @click="agenda">Guardar</b-button></template>
      </b-modal>
  </div>


</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    components: {VueJsonToCsv},
  data: () => ({
    descarga:[],
    descargar:false,
    search: null,
    searchField: null,
    tipoTicket: null,
    estatusTicket: null,
    size: 10,
    page: 1,
      totales:0,
    items: {},
    loading: false,
      agendar:false,
      model:{
        cct:"",
          agenda:"",
          tipo:null,
          correo:"",
          instalacion:"",
          aulas:0
      }
  }),
  mounted() {
    this.fetchEstados(),
    this.getTiposTicket()
      .then( () => this.setValuesFromRoute() )
      .then( () => this.loadData() )
  },
  computed: {
    ...mapState('tickets', ['tiposTicket']),
    ...mapGetters('geo', ['edoNameByClave']),
    ...mapGetters(['isStaff']),
    fields() {
      return [
        { key: 'serie', label: 'id' },
        { key: 'b12k', label: 'b12k' },
        { key: 'estado', label: 'Estado' },
        { key: 'titulo', label: 'Título' },
        { key: 'creado', label: 'Creado' },
        { key: 'actualizado', label: 'Atendido' },
        { key: 'modificador', label: 'modificador' },
        { key: 'correo', label: 'Fecha de Correo Electrónico' },
        { key: 'instalacion', label: 'Fecha de instalación' },
        { key: 'aulas', label: 'Aulas' },
        { key: 'sello', label: 'Sello' },
        { key: 'barra', label: 'Barra @prende.mx' },
        { key: 'archivos', label: 'Archivos' },
        { key: 'error_a_pendiente', label: 'Regresos a Pendiente' },
        { key: 'actions', label: 'Ver' },
      ]
    },
    pages() {
      return Math.ceil(this.items.count / this.size)
    },
    isWatcher() {
      const state = this.$store.state
      if (!state || !state.info) { return false }

      const watchers = this.tipoTicket.metadatos.watchers || []
      for (const w of watchers) {
        if (state.info.groups.includes(w)) {
          return true
        }
      }
      return false
    },

  },
  methods: {
    ...mapActions('tickets', [
      'getTiposTicket', 'getTickets','save'
    ]),
    ...mapActions('geo', ['fetchEstados']),
    setValuesFromRoute() {
      const tipo = Number(this.$route.query.tipo)
      const estatus = Number(this.$route.query.estatus)
      this.tipoTicket = this.tiposTicket.find( t => t.id === tipo )
      this.estatusTicket = Number.isNaN(estatus) ? null : estatus
      this.searchField = this.$route.query.search
      this.search = this.searchField
      this.page = this.$route.page || this.page
      this.size = this.$route.size || this.size
    },
    loadData() {
      const { tipo, page, size, search, estatus } = this.$route.query
      const params = {
        tipo, estatus,
        page: page || this.page,
        size: size || this.size,
        search: search || this.search,
      }
      if (
        !params.tipo || !params.estatus
      ) { return }

      const { final_state } = this.tipoTicket.metadatos
      params.cerrado = this.estatusTicket === final_state ?
        'true' :
        'false'

      this.loading = true
      this.getTickets(params)
        .then( v => {
          this.items = v
          this.totales = this.items.count
          this.loading = false

        })
    },
    getCriterios(solicitud,tipo=1) {
      const attach = solicitud.attachments
          .find( e => e.tipo && e.tipo.id === tipo) || {}
      return attach.criterios || {}
    },
    genera_descarga(){
      this.descargar = false
      this.descarga = []
      this.loading=true
      this.getTickets({'tipo':this.tipoTicket.id,'estatus':this.estatusTicket}).then(d=>{
        d.forEach((e,index,array)=>{

          let fs  = this.getCriterios(e,1)
          this.descarga.push({
            'id':e.serie,
            'bk12':e.metadatos.b12k?'Si':'No',
            'estado':this.edoNameByClave(e.titulo.substring(0, 2)),
            'revisor':e.modificador,
            'revision':e.actualizado,
            'Estado':e.estatus.nombre,
            'cct':e.titulo,
            'error_a_pendiente':e.error_a_pendiente,
            'fecha_correo':fs.fechaCorreo,
            'fecha_instalacion':fs.fecha,
            'aulas':fs.aulas,
            'sello':this.getCriterios(e,2).rubricas?'No':'Si' ,
            'barra': this.getCriterios(e,4).canal?'Si':'No' ,
            'mensaje':e.respuesta,

          })

        })
        this.descargar=true
        this.loading=false
        return true;

      })
    },
    agenda(){
          this.model.tipo=this.tipoTicket
          const formData = new FormData()
          formData.set('cct', this.model.cct)
          formData.set('tipo_solicitud', this.model.tipo.id)
          formData.set('procedimiento', this.model.tipo.nombre)
          formData.set('instalacion', this.model.instalacion)
          formData.set('aulas', this.model.aulas)
          formData.set('correo', this.model.correo)
          formData.set('agenda', true)
          this.save(formData).then(() => {
              this.model.cct=""
              this.model.correo=""
              this.model.instalacion=""
              this.model.aulas=0
              this.agendar = false
          })
      },
      focusCCT(){
          this.$refs.cct_alta.focus()
      }
  },
  watch: {
    tipoTicket() {
      const tipo = Number(this.$route.query.tipo)
      if (!this.tipoTicket) { return }
      if (this.tipoTicket.id === tipo) { return }
      this.$router.push({
        // path: this.$route.path,
        query: { ...this.$route.query, tipo: this.tipoTicket.id }
      })
    },
    estatusTicket() {
      const estatus = Number(this.$route.query.estatus)
      if (this.estatusTicket === estatus) { return }
      this.$router.push({
        // path: this.$route.path,
        query: { ...this.$route.query, estatus: this.estatusTicket }
      })
    },
    page() {
      const page = Number(this.$route.query.page)
      if (this.page === page) { return }
      this.$router.push({
        // path: this.$route.path,
        query: { ...this.$route.query, page: this.page }
      })
    },
    size() {
      const size = Number(this.$route.query.size)
      if (this.size === size) { return }
      this.$router.push({
        // path: this.$route.path,
        query: { ...this.$route.query, size: this.size }
      })
    },
    search() {
      const search = this.$route.query.search
      if (this.search === search) { return }
      this.$router.push({
        // path: this.$route.path,
        query: { ...this.$route.query, search: this.search }
      })
    }

    ,
    '$route.query'() {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.container { padding: 60px 0; }
.size .text { white-space: nowrap; }
.size .select { width: 100px; }
.no-items { text-align: center; font-style: italic; }
</style>
